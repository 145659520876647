import { ConsoleLabel, ConsoleRow } from '.';
import { useGlobalContext } from '../../../context/contexts/global/GlobalContext';

const stateList = [
	'none',
	'introduction',
	'selection',
	'game',
	'eind scherm',
];

const ConsoleGameStateSelect = () => {

	const {gameState , setGameState, tutorialStep, setTutorialStep, setShowHitboxes, showHitboxes} = useGlobalContext(); 
	
	return(<>
		<ConsoleRow>
			<ConsoleLabel>State Select</ConsoleLabel>
			<select onChange={(e) => setGameState && setGameState(parseInt(e.target.value))} value={gameState}>
				{stateList.map((state, index) => (
					<option key={index} label={state} value={index}/>))
				}
			</select>		
		</ConsoleRow>
		<ConsoleRow>
			<ConsoleLabel>Tutorial state Select</ConsoleLabel>
			<select onChange={(e) => setTutorialStep && setTutorialStep(parseInt(e.target.value))} value={tutorialStep}>
				{[...Array(11)].map((state, index) => (
					<option key={index} label={index.toString()} value={index}/>))
				}
			</select>
		</ConsoleRow>
		<button onClick={() => setShowHitboxes && setShowHitboxes(!showHitboxes)}>Show hitboxes</button>
	</>

	);
};

export default ConsoleGameStateSelect;