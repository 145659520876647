import { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { DialoguePackage, useDataContext } from '../../../../context/contexts/data/DataContext';
import { useGlobalContext } from '../../../../context/contexts/global/GlobalContext';
import Theme from '../../../../style/theme';
import { Button } from '../../../Buttons/Buttons';
import { DialogueButton } from './DialogueButton';
import { DialogueHeader } from './DialogueHeader';
import { DialogueRemark } from './DialogueRemark';
import { DialogueResponses } from './DialogueResponses';
import { DialogueWindow } from './DialogueWindow';
import soundlist from '../../../../assets/Sounds';
import { useAudioContext } from '../../../../context/contexts/audio/AudioContext';
import { usePuzzleContext } from '../../../../context/contexts/puzzle/PuzzleContext';


export const Dialogue = () => {

	const { activeDialogueMessage, setActiveDialogueMessage, activeScene } = useGlobalContext();
	const { data } = useDataContext();
	const {playSound} = useAudioContext();
	const {puzzleData, activePuzzle} = usePuzzleContext();


	const [conversation, setConversation] = useState<boolean>(false);
	const [history, setHistory] = useState<string[]>([]);
	const [remarkPushed, setRemarkPushed] = useState<boolean>(false);
	const [selection, setSelection] = useState<number>(1);

	// single DialoguePackage which is active at this moment. Can be seen as the last DialoguePackage which has been added to the dialogue.

	const message = useMemo<DialoguePackage | undefined>(() => {

		if(activeDialogueMessage && history.includes(activeDialogueMessage)) return;
		// reset pushed before message is updated. this way children have the new state of pushed on time.
		setRemarkPushed(false);

		// 
		if(!data || !activeDialogueMessage || !data.dialogue || !data.dialogue[activeDialogueMessage]) {
			return undefined;
		}
		else if(data.dialogue[activeDialogueMessage].object.sender === 2 && !conversation && !data.dialogue[activeDialogueMessage].object.start_conversation){
			return undefined;
		}
		else {
			return data.dialogue[activeDialogueMessage];
		}

	},[activeDialogueMessage]);

	// when conversation changes..

	useEffect(() => {
		// when the conversation is opened, set pushed to true
		if(conversation){
			setRemarkPushed(true);
		}
		else{
			setActiveDialogueMessage && setActiveDialogueMessage('');
		}
	},[conversation]);

	// when message changes...

	useEffect(() => {
		if(!message) return;

		// open conversation if indicated
		if(message.object.start_conversation){
			setConversation(true);
			playSound && playSound(soundlist['messageReceived'], 0.4);

		}

		if(message.object.start_conversation || !message.object.start_conversation && !conversation){
			setHistory(a => 
				[...a, 'spacer']
			);
		}

		// add new string to history
		if(activeDialogueMessage)
			setHistory(a => 
				[...a, activeDialogueMessage]
			);

		// set pushed to true when the conversation is open
		if(conversation){
			setRemarkPushed(true);
		}

	},[message]);


	useEffect(() => {
		setHistory([]);
	}, [activePuzzle]);

	useEffect(() => {
		//puzzle 1 end dialogues
		if(conversation === false)
			if(puzzleData && puzzleData[1].checks[3] === 1 && puzzleData[1].checks[4] === 1 && puzzleData[1].checks[5] === 1){
				setActiveDialogueMessage && setActiveDialogueMessage(`puzzel1.nascannen.${activeScene === 1 ? 'modi' : 'sandra'}-1`);
			}
	},[conversation]);

	// dictates the state of the dialogue button

	const buttonState = useMemo<number>(() => { // 0 === default, 1 === typing, 2 === send, 3 === exit, 
		if(!message){ // if message is undefined, check conversation for either of 2 states.
			if(!conversation) return 0;
			else return 3;
		}

		if(!conversation){ // if conversation is false, state is always default
			return 0;
		}
		else{ // if conversation is true...
			if(message.object.follow_up_message) return 1;
			else if(message.object.response_1) return 2;
			else return 3;
		}
	},[message, conversation]);

	// onClick function for the dialogue button

	const buttonHandler = () => {
		switch(buttonState){
		case 0:
			setConversation(true);
			break;
		case 1:
			// button is disabled and waiting for the followup message
			break;
		case 2:
			// enable next message based on selected response
			if(message && message.object.response_1)
				setActiveDialogueMessage && setActiveDialogueMessage(message.object[`response_${selection}`]);
			break;
		case 3:
			setConversation(false);
			break;
		}
	};

	const [visible, setVisible] = useState<boolean>(false);

	const visibleTimeout = useRef<NodeJS.Timeout>();
	useEffect(() => {
		setSelection(1);

		if(!message) return;
		
		setVisible(false);

		if(visibleTimeout.current)
			clearTimeout(visibleTimeout.current);
		visibleTimeout.current = setTimeout(() => {
			setVisible(true);
		},2000);

		return () => {
			if(visibleTimeout.current)
				clearTimeout(visibleTimeout.current);
		};

	},[message]);

	return (
		<Container>
			<Subcontainer>
				{ conversation ?				
					<DialogueWindow message={message} history={history} conversation={conversation}/> :
					<DialogueRemark message={message} pushed={remarkPushed} setPushed={() => setRemarkPushed(true)}/>
				}
			</Subcontainer>
			<BottomContainer conversation={conversation}>
				{ conversation && <Spacer/>}
				<DialogueResponses messageVisability={visible} message={message} conversation={conversation} setSelection={(a) => setSelection(a)}/>
				<DialogueButton usable={true} state={buttonState} onClick={() => buttonHandler()}/>
			</BottomContainer>
		</Container>
	);
};

// styled components

const Container = styled.div`
	position: absolute;
	right:0;top:0;bottom:0;

	/* background-color: hotpink; */

	display: flex;
	flex-direction: column;
	width: 80%;

	// Extra small screens
	@media (min-width: ${Theme.responsive.media.xs}){
		/* width: 50vw;  */
		margin: ${Theme.responsive.whitespace.xs  / 2}px;
	}

	// Small screens (tablets, big phones, small monitors).
	@media (min-width: ${Theme.responsive.media.sm}){
		/* width: 40vw;  */
		margin: ${Theme.responsive.whitespace.sm  / 2}px;

	}

	// Large screens
	@media (min-width: ${Theme.responsive.media.lg}){
		/* width: 35vw;  */
		margin: ${Theme.responsive.whitespace.lg / 2}px;

	}

	// Extra large screens
	@media (min-width: ${Theme.responsive.media.xl}){
		/* width: 30vw;  */
		margin: ${Theme.responsive.whitespace.xl / 3}px;

	}

	pointer-events: none!important;

	& > * {
		pointer-events: all;
	}
`;

const Subcontainer = styled.div`
	position: relative;
	flex-grow: 1;
	/* background-color: purple; */

	pointer-events: none;

	& > * {
		pointer-events: all;
	}
`;

const BottomContainer = styled.div`
	position: relative;
	width: 100%;
	display:flex;

	pointer-events: none;

	& > * {
		pointer-events: all;
	}

	background-color: ${p => p.conversation ? 'rgba(0,0,0,0.65)' : ''};
	padding: 10px;
	border-radius:  0 0 10px 10px;
`;

const Spacer = styled.div`
	position: absolute ;
	left:50%;
	transform: translateX(-50%);
	top:0;
	width: 100%;
	height: 2px;
	background-color: ${Theme.colors.primary};
`;

// types

export type DialogueProps = {
	message: DialoguePackage | undefined;
	history?: string[];
	conversation?: boolean;
}