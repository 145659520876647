import { useGlobalContext } from '../../../context/contexts/global/GlobalContext';
import { ReactNode, useMemo } from 'react';
import { render } from '@testing-library/react';


export const RoomCheck = (props: RoomCheckProps) => {
		
	const {activeScene, activeRoom, activatedRooms} = useGlobalContext();

	// an check to decide wether or not to render a scene
	const renderCheck = useMemo<boolean>(() => {
		let c = false;

		if(!activatedRooms || !activeRoom || !activeScene) return false;

		if(props.scene && activatedRooms.includes(`${props.roomKey}-${props.scene}`)){
			c = true;
		}
		else if(!props.scene && activatedRooms.includes(`${props.roomKey}`)){
			c = true;
		}

		return c;
	},[activeScene, activeRoom, activatedRooms]);

	// an check to decide wether or not the scene should be active
	const activeCheck = useMemo<boolean>(() => {
		let c = false;

		if(!activeRoom) return false;

		if(!props.scene && activeRoom === props.roomKey){
			c = true;
		}
		else if(props.scene && activeScene === props.scene && activeRoom === props.roomKey){
			c = true;
		}

		return c;
	},[activeScene, activeRoom]);

	return(
		<> 
			{ activatedRooms && renderCheck &&
				<group position={activeCheck ? [0,0,0] : [9999,9999,9999]}>
					{props.children}
				</group>
			}
		</>
	);
};

type RoomCheckProps = {
		children: ReactNode,
		roomKey: string,
		scene?: number,
}