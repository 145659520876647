import { useMemo, useRef } from 'react';
import { useCameraMovementContext } from './CameraMovement';
import { MeshProps, useLoader, useThree } from '@react-three/fiber';
import { Group, Mesh, TextureLoader, Vector3 } from 'three';
import { useGlobalContext } from '../../../../context/contexts/global/GlobalContext';
import image from '../../../../assets/images/movespotimage.png';
import { Billboard, Float } from '@react-three/drei';

const MoveSpot = (props: MeshProps) => {

	const {tutorialStep, setTutorialStep} = useGlobalContext();
	const { cameraType, move } = useCameraMovementContext();
	const spot = useRef<Group>(null);
	const {camera} = useThree();
	const texture = useLoader(TextureLoader, image);

	const activate = () => {

		// complete tutorial step
		if(tutorialStep === 1) setTimeout(() => {setTutorialStep && setTutorialStep(2);},1000); 

		if(!spot.current || !move) return;
		const newPos = spot.current.position;
		move(new Vector3(newPos.x, camera.position.y, newPos.z));
	};

	const visible = useMemo(() => {
		if(cameraType !== 'movement' || !spot.current) return false;
		else return true;
	},[cameraType, camera, spot.current]);

	return ( 
		<Billboard follow position={props.position} ref={spot}>
			<mesh {...props} visible={visible} onClick={(e) => {e.stopPropagation(); visible && activate();}} scale={0.5} position={[0,0,0]}>
				<planeGeometry/>
				<meshBasicMaterial map={texture} transparent opacity={.5}/>
			</mesh>
		</Billboard>
	);
};
 
export default MoveSpot;