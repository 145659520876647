import { ReactNode } from 'react';
import styled from 'styled-components';


// sc

const HeadingBlock = styled.h1`
	background-color: ${p => p.theme.colors.primary};
	padding: .5em 1em;
	margin: 0;
	text-align: center;
`;
 
export default HeadingBlock;