import { OrbitControls } from '@react-three/drei';
import { OrbitControls as OrbitControlsType } from 'three-stdlib';
import { useThreeContext } from '../../../context/contexts/three/ThreeContext';
import { useEffect, useRef } from 'react';
import { useGlobalContext } from '../../../context/contexts/global/GlobalContext';
import { useCameraMovementContext } from './Movement/CameraMovement';

const Controls = () => {

	const {controlsEnabled, targetPosition, verticalRestriction, horizontalRestriction} = useThreeContext();
	const {inspectingObject} = useCameraMovementContext();

	const controls = useRef<OrbitControlsType>(null);

	useEffect(() => {
		if(!controls.current) return;

		controls.current.dampingFactor = 0.1;
		
	},[]);

	useEffect(() => {
		if(!controls.current || !targetPosition || targetPosition === controls.current?.target) return;
		controls.current.target = targetPosition;
	},[targetPosition]);

	return (
		<OrbitControls 
			ref={controls}
			enableDamping={true}
			enablePan={false}
			enableZoom={false}
			reverseOrbit={inspectingObject ? false : true}
			minAzimuthAngle={horizontalRestriction && horizontalRestriction[0]}
			maxAzimuthAngle={horizontalRestriction && horizontalRestriction[1]}
			minPolarAngle={verticalRestriction && verticalRestriction[0]}
			maxPolarAngle={verticalRestriction && verticalRestriction[1]}
			rotateSpeed={0.4}
			enabled={controlsEnabled}
			regress
		/>
	);
};


export default Controls;