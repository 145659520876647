import { useThree } from '@react-three/fiber';
import { ConsoleLabel, ConsoleRow } from '.';


const CameraConsole = () => {

	// const{camera} = useThree();

	// const getPosition = () => {

	// };

	return (
		<ConsoleRow>
			<ConsoleLabel>Camera</ConsoleLabel>
			{/* <button onClick={getPosition}>get current position</button> */}

		</ConsoleRow>
	);
};

export default CameraConsole;