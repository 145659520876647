import { useEffect, useState } from 'react';

import styled from 'styled-components';
import Theme from '../../style/theme';

interface Size {
    width: number;
    height: number;
}

const ForceLandscape = () => {

	const [size, setSize] = useState<Size>({width: window.innerWidth, height: window.innerHeight});

	const render = size.width < 1000 && size.width < size.height;

	useEffect(() => {
		window.onresize = resizeHandler;
	},[]);

	const resizeHandler = () => {
		setSize({height: window.innerHeight, width: window.innerWidth});
	};

	return (
		<>
			{ render &&
                <FullScreenMessage><Textbox>
						Put your device on landscape mode or resize your window</Textbox>
                </FullScreenMessage>
			}
		</>
	);
};

//styled components

const FullScreenMessage = styled.div`
    position: absolute;
    left:0;
	top:0;
	bottom:0;
	right:0;
    z-index: 9001;

	width: 100vw;
	height: 100vh;

    background: ${Theme.colors.primary};

	display: flex;
    justify-content: center;
    align-items: center;
`;

const Textbox = styled.div`

    z-index: 9001;

    font-size: 5vh;
    text-align: center;
`;

export default ForceLandscape;