import { Select } from '@react-three/postprocessing';
import { fork } from 'child_process';
import { Children, ReactNode, useContext, useEffect, useState } from 'react';
import { LoopOnce } from 'three';
import { clamp } from 'three/src/math/MathUtils';
import { useAudioContext } from '../../../../context/contexts/audio/AudioContext';
import { useThreeContext } from '../../../../context/contexts/three/ThreeContext';
import { useCameraMovementContext } from '../../controls/Movement/CameraMovement';
import { InteractiveObjectContext } from './InteractiveObject';
import { PointerHandler } from './PointerHandler';

const defaultParameters = {
	treshold: 0.75,
	dragRange: 100, //minus value for dragging in other direction
	dragDirection: 'vertical'
};


const InteractiveElement = (props: ElementProps) => {	
	const {setControlsEnabled} = useThreeContext();
	const {cameraType, inspectingObject} = useCameraMovementContext();
	const {objectId} = useContext(InteractiveObjectContext);
	const {playSound} = useAudioContext();

	const select = props.usable ? props.usable : 'usable';

	const obligations = cameraType === 'inspection' && objectId === inspectingObject && select === 'usable';

	const [animationComplete, setAnimationComplete] = useState<boolean>(false);	
	const [isDragging, setIsDragging] = useState<boolean | undefined>();

	const [offset, setOffset] = useState<number>(0);
	const [startValue, setStartValue] = useState<number>(0);

	const [time, setTime] = useState<number>(0);  
	const range = props.parameters ? props.parameters.dragRange : defaultParameters.dragRange;

	const onPointerDown = (e: any) => {	
		if(!animationComplete && obligations){
			if(props.type === 'switch') {
				// props.actions[props.actionName].reset();	
				props.actions[props.actionName].paused = false;	
				props.actions[props.actionName].enabled = true;	
			}		
			//set animation with timescale 0 so its controlled by time usestate
			props.actions[props.actionName].play();
			props.actions[props.actionName].setLoop(LoopOnce, 1);		
			props.actions[props.actionName].timeScale = 0;

			const start = props.parameters?.dragDirection == 'horizontal' ? (e.screenX / window.innerWidth * 100) : (e.screenY / window.innerHeight * 100);
			setStartValue(start);
			setOffset(props.actions[props.actionName].time/props.actions[props.actionName]._clip.duration * range);
			setIsDragging(true);		
			//disable orbit controls
			setControlsEnabled && setControlsEnabled(false);

			window.addEventListener('pointerup', onPointerUp, {once : true});
		}
	};

	const onPointerUp = () => {
		setIsDragging(false);
		//enable orbit controls
		setControlsEnabled && setControlsEnabled(true);
	};

	useEffect(() => {		
		if(isDragging == false){
			if(props.type === 'switch'){	
				//switch interaction functionalities
				const treshold = props.parameters ? props.parameters.treshold : defaultParameters.treshold;
				const timeCalculation = time / props.actions[props.actionName]._clip.duration;

				if(timeCalculation >= treshold){
					//auto complete animation when treshold is reached
					props.actions[props.actionName].play();
					props.actions[props.actionName].timeScale = 1;			
					props.actions[props.actionName].clampWhenFinished = true;
					setAnimationComplete(true);
				} else {
					//auto reverse animation when treshold is not yet reached
					props.actions[props.actionName].play();
					props.actions[props.actionName].timeScale = -0.3;			
				}
			} else if (props.type === 'displace'){
				//displace interaction functionalities
			}
		}

		//set eventlistener to listen tand remove event when false
		if (isDragging) window.addEventListener('pointermove', onMouseMove); 
		return () => window.removeEventListener('pointermove', onMouseMove);
	}, [isDragging]);

	const onMouseMove = (e: any) => {

		//calculation to set time value based on duration of the clip, pixel range and mouse movement
		const diff = props.parameters?.dragDirection == 'horizontal' ? (e.screenX / window.innerWidth * 100) : (e.screenY / window.innerHeight * 100);	
		const v = clamp((startValue - diff + offset) / range, 0, 1) * props.actions[props.actionName]._clip.duration;	
		setTime(v);			
	};

	useEffect(() => {
		//set time of the animation based on time usestate
		props.actions[props.actionName].time = time;
	},[time]);

	useEffect(() => {
		//callback function when switch interaction is completed
		if(animationComplete)
			props.onInteractionComplete && props.onInteractionComplete();
	},[animationComplete]);

	const [backwards, setBackwards]= useState<boolean>(false);
	const [clicked, setClicked] = useState<boolean>(false);

	const onClickFunctions = () => {
		if(!obligations) return;
		//given function
		props.onClick && props.onClick();
		props.sound && playSound && playSound(props.sound[0]);
		//play animation if type is just click
		if(props.type === 'click'){	

			if(clicked && props.norepeat) return;
			setClicked(true);

			props.actions[props.actionName].timeScale = 10;
			props.actions[props.actionName].setLoop(LoopOnce,1);
			props.actions[props.actionName].play();
			if(!props.playBackwards){
				props.actions[props.actionName].reset();
			} else {
				if(props.playBackwards && !backwards) {	
					props.actions[props.actionName].paused = false;	
					props.actions[props.actionName].timeScale = 1;
					props.actions[props.actionName].setLoop(LoopOnce);
					props.actions[props.actionName].clampWhenFinished = true;
					setBackwards(true);
				} else {
					if(backwards){
						props.sound && playSound && playSound(props.sound[1]);
						props.actions[props.actionName].timeScale = -1;
						props.actions[props.actionName].paused = false;	
						setBackwards(false);
					}
				}
			}
		}
	};

	return (
		<group 
			onPointerDown={e => {
				if(objectId !== inspectingObject) return;
				e.stopPropagation(); 				
				if(props.type === 'click') return;
				onPointerDown(e);
			}}
		>					
			<PointerHandler interactive={props.type === 'click' && cameraType === 'inspection' && inspectingObject === objectId ? true : false} onInteract={() => onClickFunctions()}>
				<Select enabled={obligations}>
					{Children.toArray(props.children)[0]}
				</Select>				
				{Children.toArray(props.children)[1]}
			</PointerHandler>

		</group>
	);
};

//types
type InteractionType = 'switch' | 'displace' | 'click'

interface ElementProps {
	type: InteractionType	
	parameters?: InteractionParameters
	playBackwards?: boolean //when clicked again play the animation backwards (drawers for example), only with type click

	actions: any, 
	actionName: string,

	onInteractionComplete?: () => void, //callback function
	onClick?: () => void, //click function

	children: ReactNode,
	usable?: 'usable' | 'notusable',
	norepeat?: boolean,

	sound?: string[], //first is standard onclick sound, second is playback sound
}

type InteractionParameters = {
	treshold: number, // 0-1 which represents an percentage
	dragRange: number, //in pixels -value to drag to the other side
	dragDirection: 'horizontal' | 'vertical'
} 

export default InteractiveElement;
