import { ConsoleLabel, ConsoleRow } from '.';
import { useInventoryContext } from '../../../context/contexts/inventory/InventoryContext';

const items = [
	'verbruiksmeter',
	'wieken',
	'leiding pijpje',
	'zonnepanelen',
	'gaskraan',
	'luik hendel',
	'gasleiding'	,
	'normale kabel',
	't kabel',
	't kabel 02',
	'kruis kabel',
	'normale kabel 2',
	'bocht',
];

const InventoryConsole = () => {

	const {addToInventory} = useInventoryContext();
	return (
		<ConsoleRow>
			<ConsoleLabel>Inventory</ConsoleLabel>
			<button onClick={() => addToInventory && addToInventory(23)}>key</button>

			{items.map((item, index) => {
				return (
					<button onClick={() => addToInventory && addToInventory(index)} key={index}>{item}</button>
				);	
			})}
		</ConsoleRow>
	);
};

export default InventoryConsole;