import { directus } from '../../../../utilities/directus';

const dialogueRequest = async() => {

	const response = {};

	await directus.items('dialogue_messages').readByQuery({limit: -1, meta: '*'}).then((data) => {
		if(!data.data) return;

		data.data.map((value) => {
			if(!value || !value.key) return;
			response[value.key] = 
            {object: value, translation: null};
		});
	});

	await directus.items('dialogue_messages_translations').readByQuery({limit: -1, meta: '*'}).then((data) => {
		if(!data.data) return;

		data.data.map((value) => {
			if(!value || !value.id || !value.dialogue_messages_key) return;
			response[value.dialogue_messages_key].translation = value;
		});
	});

	return {
		response : response,
		collection: 'dialogue'
	};

};

export default dialogueRequest;