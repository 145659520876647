import { useMemo } from 'react';
import styled from 'styled-components';
import { useDataContext } from '../../../../context/contexts/data/DataContext';
import { Button } from '../../../Buttons/Buttons';
import { DialogueResponses } from './DialogueResponses';
import IsTypingAnimation from './IsTypingAnimation';

export const DialogueButton = (props: DialogueButtonProps) => {

	const {getFileURL} = useDataContext();

	const icon = useMemo(() => {
		switch(props.state){

		case 1:
			// chat in progress (dots with animation?)
			return getFileURL && getFileURL('277ae723-91fe-403b-bf40-461367565eb4');
		case 2:
			// send option (arrow)
			return getFileURL && getFileURL('03249657-971a-4bc2-8d84-0ed3852f8131');
		case 3:
			// exit chat (exit)
			return getFileURL && getFileURL('52542410-909f-4f73-b802-86534731138c');
		default:
			return getFileURL && getFileURL('46581e05-2fd5-4fc4-9a50-2b2c5acc9fc1');
		}
	},[props.state]);

	return(
		<StyledButton
			disabled={props.state === 1 || props.usable === false}
			iconURL={icon}
			onClick={() => props.onClick()}
		>
			{ props.state === 1 &&
				<IsTypingAnimation></IsTypingAnimation>
			}
		</StyledButton>
	);
};

// styled components

const StyledButton = styled(Button)`
	margin-left: auto;
`;

// types

type DialogueButtonProps = {
    state: number;
    onClick: () => void;
	usable: boolean;
}