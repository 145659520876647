import styled from 'styled-components';
import { Backdrop } from './components/Container';
import Theme from '../../style/theme';
import { usePuzzleContext } from '../../context/contexts/puzzle/PuzzleContext';
import { useGlobalContext } from '../../context/contexts/global/GlobalContext';
import { Button } from '../Buttons/Buttons';
import themeselect1 from '../../assets/images/themeselect1.jpg';
import themeselect2 from '../../assets/images/themeselect2.jpg';
import themeselect3 from '../../assets/images/themeselect3.jpg';
import themeselect4 from '../../assets/images/themeselect4.jpg';
import HeadingBlock from '../interface/components/HeadingBlock';

const PuzzleSelection = () => {

	const {setActivePuzzle} = usePuzzleContext();
	const {setGameState} = useGlobalContext();

	const selectLevel = (level: number) => {
		setActivePuzzle && setActivePuzzle(level);
		setGameState && setGameState(0);
	};

	const themeData = [
		{
			title: 'Energiegebruik',
			img: themeselect1,
			text: 'Onderzoek met een energiemeter hoeveel energie verschillende apparaten gebruiken om dit level te halen! '
		},
		{
			title: 'Energie opwekken',
			img: themeselect2,
			text: 'Volbreng deze puzzel door duurzame manieren van energie opwekken te ontdekken en kijk wat het best bij de bewoner past! '
		},
		{
			title: 'Energie transport',
			img: themeselect3,
			text: 'Verleg de leidingen om energie op de juiste manier door het huis te verplaatsen en rond dit level af!'
		},
		{
			title: 'Energieplan',
			img: themeselect4,
			text: 'Maak een energieplan voor de bewoner om de woning te verduurzamen en voltooi deze puzzel!'
		},
	];

	return ( 
		<Backdrop>
			<Container>
				<TopRow>
					<h1>Selecteer een thema</h1>
					<Button style={{height: '50%'}} onClick={() => selectLevel(0)}>Tutorial</Button>
				</TopRow>
				<Grid>
					{themeData.map((value, index) => {
						return (
							<>
								<StyledHeadingBlock>{value.title}</StyledHeadingBlock>
								<StyledImg src={value.img} style={{gridRowStart: `${2}`, order: `${index}`}}></StyledImg>	
								<Content style={{gridRowStart: `${3}`}}>
									{value.text}
									<Button style={{fontSize: '0.8em', width: 'max-content', marginTop: 'auto'}} onClick={() => selectLevel(index+1)}>Speel thema</Button>
								</Content>
							</>
						);
					})}
				</Grid>
			</Container>
		</Backdrop> 
	);
};

// styled

const Grid = styled.div`
	flex-grow: 1;
	width: 100%;
	display: grid;
	column-gap: 1em;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: auto 1fr 1fr;
`;

const TopRow = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const StyledHeadingBlock = styled(HeadingBlock)`
	font-size: 1.5em!important;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Content = styled.div `
	background-color: rgba(0, 0, 0, 0.7);
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	// Extra small screens
	@media (min-width: ${Theme.responsive.media.xs}){
		padding: ${Theme.responsive.whitespace.xs/3}px;
		gap: ${Theme.responsive.whitespace.xs/3}px;
	}

	// Small screens (tablets, big phones, small monitors).
	@media (min-width: ${Theme.responsive.media.sm}){
		padding: ${Theme.responsive.whitespace.sm/3}px;
		gap: ${Theme.responsive.whitespace.sm/3}px;
	}

	// Large screens
	@media (min-width: ${Theme.responsive.media.lg}){
		padding: ${Theme.responsive.whitespace.lg/3}px;
		gap: ${Theme.responsive.whitespace.lg/3}px;
	}

	// Extra large screens
	@media (min-width: ${Theme.responsive.media.xl}){
		padding: ${Theme.responsive.whitespace.xl/3}px;
		gap: ${Theme.responsive.whitespace.xl/3}px;
	}
`;

const StyledImg = styled.div<{src: string}>`
	background-image: url(${p => p.src});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	background-color: rgba(0, 0, 0, 0.7);

	flex-grow: 1;
	width: 100%;
`;

const Container = styled.div`
	height:100%;width:100%;
	text-align: left;
	display: flex;
	flex-direction: column;
	color: ${Theme.colors.neutralLightest};

	overflow-y: auto;

	// Extra small screens
		@media (min-width: ${Theme.responsive.media.xs}){
		padding: ${Theme.responsive.whitespace.xs}px;
	}

	// Small screens (tablets, big phones, small monitors).
	@media (min-width: ${Theme.responsive.media.sm}){
		padding: ${Theme.responsive.whitespace.sm}px;
	}

	// Large screens
	@media (min-width: ${Theme.responsive.media.lg}){
		padding: ${Theme.responsive.whitespace.lg}px;
	}

	// Extra large screens
	@media (min-width: ${Theme.responsive.media.xl}){
		padding: ${Theme.responsive.whitespace.xl}px;
	}
`;
 
export default PuzzleSelection;