import { createGlobalStyle } from 'styled-components';
import Theme from './theme';

// eslint-disable-next-line
const fonts = require('../assets/fonts/fonts.css');

const GlobalStyle = createGlobalStyle`

	${fonts};


	html {
		height: -webkit-fill-available;
		min-height: 100%;
		height: 100%;
		font-size: 1.1em;
		user-select: none;	
		scroll-behavior: smooth;
	}

	body {
		margin:0;
		padding:0;
		height: 100%;
		overscroll-behavior: contain;
		overflow: hidden;
		font-family: 'FS Elliot Pro';


		// Extra small screens
		@media (min-width: ${Theme.responsive.media.xs}){
			font-size: 14px;
		}

		// Small screens (tablets, big phones, small monitors).
		@media (min-width: ${Theme.responsive.media.sm}){
			font-size: 18px;
		}

		// Large screens
		@media (min-width: ${Theme.responsive.media.lg}){
			font-size: 20px;
		}

		// Extra large screens
		@media (min-width: ${Theme.responsive.media.xl}){
			font-size: 28px;
		}
	}

	h1, h2, h3 {

	}

	h2 {
		color: ${Theme.colors.primary};
		// Extra small screens
		@media (min-width: ${Theme.responsive.media.xs}){
			font-size: 14px;
		}

		// Small screens (tablets, big phones, small monitors).
		@media (min-width: ${Theme.responsive.media.sm}){
			font-size: 18px;
		}

		// Large screens
		@media (min-width: ${Theme.responsive.media.lg}){
			font-size: 20px;
		}

		// Extra large screens
		@media (min-width: ${Theme.responsive.media.xl}){
			font-size: 28px;
		}
	}

	* {
		box-sizing: border-box;
	}

	#root {
		height: 100%;
	}

	/* width */
	::-webkit-scrollbar {
	width: 7.5px;
	}

	/* Track */
	::-webkit-scrollbar-track {
	background: ${Theme.colors.neutral};
	}
	
	/* Handle */
	::-webkit-scrollbar-thumb {
	background:  ${Theme.colors.neutralLightest}; 
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
	background: #555; 
	}

`;

export default GlobalStyle;
