import { lazy, useEffect } from 'react';
import { RoomCheck } from '../../RoomCheck';
import MoveSpot from '../../../controls/Movement/MoveSpot';

const roomKey = 'kelder';

const KelderRoom = lazy(() => import('./KelderRoom'));
const HuisDoorsnede = lazy(() => import('./PuzzleElements/Huis_doorsnede'));
const Printer = lazy(() => import('./PuzzleElements/3d_printer'));

export const Kelder = () => {

	// render
	return (
		<RoomCheck roomKey={roomKey}>
			<MoveSpot position={[-1.7,0.3,-1.5]}/>
			<KelderRoom/>
			<HuisDoorsnede scale={2.2} position={[0,0,-3.93]}/>
			<Printer position={[0,0.3,-1]}/>
		</RoomCheck>
	);
};