import backgroundMusic from './MUSIC/LIVING RAIN_ARCADIS.mp3';
import buttonclick from './button_click.mp3';
import dooropen from './door_open.mp3';
import dooropenandclose from './door_openandclose.mp3';
import drawerclose from './drawer_close.mp3';
import draweropen from './drawer_open.mp3';
import genericplace from './generic_place.mp3';
import hintavailable01 from './hint_available01.mp3';
import hintavailable02 from './hint_available02.mp3';
import ladderclimb from './ladder_climb.mp3';
import messagereceived from './message_received.mp3';
import pizzaisready from './pizza_isready.mp3';
import puzzlecompleted from './puzzle_completed.mp3';
import secretpassage from './secret_passage.mp3';
import trapdooropen from './trapdoor_open.mp3';

const soundlist = {
	'backgroundMusic' : backgroundMusic,
	'buttonClick' : buttonclick,
	'doorOpen' : dooropen,
	'doorOpenAndClose' : dooropenandclose,
	'drawerClose' : drawerclose,
	'drawerOpen' : draweropen,
	'genericPlace' : genericplace,
	'hintAvailable01' : hintavailable01,
	'hintAvailable02' : hintavailable02,
	'ladderClimb' : ladderclimb,
	'messageReceived' : messagereceived,
	'pizzaIsReady' : pizzaisready,
	'puzzleCompleted' : puzzlecompleted,
	'secretPassage' : secretpassage,
	'trapdoorOpen' : trapdooropen,

};

export default soundlist;
