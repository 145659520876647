import { ConsoleLabel, ConsoleRow, FieldLabel } from '.';
import { useDataContext } from '../../../context/contexts/data/DataContext';
import { useGlobalContext } from '../../../context/contexts/global/GlobalContext';


const DialogueConsole = () => {

	const {activeDialogueMessage, setActiveDialogueMessage} = useGlobalContext(); 
	const {data} = useDataContext();

	return(
		<>
			<ConsoleRow>
				<ConsoleLabel>Dialogue</ConsoleLabel>
			</ConsoleRow>
			<ConsoleRow>
				<FieldLabel>active dialogue message</FieldLabel>
				<input style={{marginRight: '20px'}} onChange={(e) => setActiveDialogueMessage && setActiveDialogueMessage(e.target.value)} value={activeDialogueMessage}>
				</input>
			</ConsoleRow>
		</>

	);
};

export default DialogueConsole;