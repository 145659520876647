import styled from 'styled-components';
import { keyframes } from 'styled-components';
import { usePuzzleContext } from '../../../../context/contexts/puzzle/PuzzleContext';
import Theme from '../../../../style/theme';
import HintButtons from '../HintButtons';
import { ShowInteractablesButton } from '../ShowInteractablesButton';
import { Inventory } from './Inventory';
import { ToggleSound } from './ToggleSound';
import { ExitButton } from './Timer';

export const SideBar = () => {

	const {puzzleData} = usePuzzleContext();

	return (<>
		{puzzleData && <Container>
			{puzzleData[0].checks[2] === 1 && <HintButtons/>}
			{puzzleData[0].checks[4] === 1 && <Inventory/>}
			{puzzleData[0].checks[3] === 1 && <ShowInteractablesButton/>}
			<ToggleSound/>			
			<ExitButton/>
		</Container>}
	</>
	);
};

// styled components
const Container = styled.div`
	display: flex; flex-direction: column; justify-content: flex-end;
	/* position: absolute; left:0;top:0; */
	height: 100%;
	/* width: 200px; */

	& > button {
		// Extra small screens
		@media (min-width: ${Theme.responsive.media.xs}){
			margin: ${Theme.responsive.whitespace.xs  / 2}px;
		}

		// Small screens (tablets, big phones, small monitors).
		@media (min-width: ${Theme.responsive.media.sm}){
			margin: ${Theme.responsive.whitespace.sm / 2}px;

		}

		// Large screens
		@media (min-width: ${Theme.responsive.media.lg}){
			margin: ${Theme.responsive.whitespace.lg / 2}px;

		}

		// Extra large screens
		@media (min-width: ${Theme.responsive.media.xl}){
			margin: ${Theme.responsive.whitespace.xl / 3}px;
		}
	}
`;