import { GroupProps } from '@react-three/fiber';
import { forwardRef, useEffect, useState } from 'react';
import { Group } from 'three';

export const PointerHandler = forwardRef<Group, GroupProps & PointerHandlerProps>((props, ref) => {
	
	const [moved, setMoved] = useState<boolean>(false);
	const [moves, setMoves] = useState<number>(0);
	const [tracking, setTracking] = useState<boolean>(false);
	const [pointerState, setPointerState] = useState<'down' | 'up' | 'out'>();

	useEffect(() => {
		if(moves > 2 && !moved) {setMoved(true);}
	},[moves]);

	useEffect(() => {
		if(!tracking){
			setMoves(0);
			setPointerState('out');
			setMoved(false);
		}
	},[tracking]);

	useEffect(() => {
		if(pointerState === 'up'){
			if(moved){
				setMoved(false); 
			}
			else if(tracking){
				props.onInteract();
			}
			setTracking(false);
		}
	},[pointerState]);

	return (<>
		{props.interactive === false ?	
			<group>
				{// eslint-disable-next-line react/prop-types
					props?.children
				}
			</group>
			:
			<group
				onPointerUp={(e) => {e.stopPropagation(); setPointerState('up'); }}
				onPointerDown={(e) => {e.stopPropagation(); setPointerState('down'); setTracking(true);}}
				onPointerMove={(e) => {e.stopPropagation(); pointerState === 'down' && setMoves(a => a + 1);}}
			>
				{ // eslint-disable-next-line react/prop-types
					props?.children
				}
			</group>
		}
	</>);
});

// types

type PointerHandlerProps = {
    onInteract: () => void,
	interactive?: boolean
}