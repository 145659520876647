import styled from 'styled-components';
import { keyframes } from 'styled-components';
import { useTextContext } from '../../context/contexts/text/TextContext';
import Theme from '../../style/theme';
import { Backdrop } from './components/Container';
import { SelectionBlock } from './components/SelectionBlock';

const Selection = () => {

	const {t} = useTextContext();


	return (
		<Backdrop>
			<Container>
				<Text>
					<h1>{t('selection.heading')}</h1>
					{/* <p>{t('selection.description')}</p> */}
				</Text>
				<SelectionBlock/>
			</Container>
		</Backdrop>
	);
};

// styled components

const fadeIn = keyframes`

	from {
		opacity: 0;
		& > * {
			opacity: 0;
		}
	}

	to {
		opacity: 1;
		& > * {
			opacity: 0;
		}
	}

`;

const Container = styled.div`
	color: ${Theme.colors.neutralLightest};
	text-align: left;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	background: linear-gradient(90deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.6) 60%, rgba(0,0,0,0) 100%);

	animation: ${fadeIn} 1s forwards;

	// Extra small screens
	@media (min-width: ${Theme.responsive.media.xs}){
		padding: ${Theme.responsive.whitespace.xs}px;
	}

	// Small screens (tablets, big phones, small monitors).
	@media (min-width: ${Theme.responsive.media.sm}){
		padding: ${Theme.responsive.whitespace.sm}px;
	}

	// Large screens
	@media (min-width: ${Theme.responsive.media.lg}){
		padding: ${Theme.responsive.whitespace.lg}px;
	}

	// Extra large screens
	@media (min-width: ${Theme.responsive.media.xl}){
		padding: ${Theme.responsive.whitespace.xl}px;
	}

	overflow-y: auto;

`;

const Text = styled.div`
	position: relative;
	width: 100%; // change for different devices
	text-align: left;

	& > * {
		max-width: 50%;
		margin: 0;
	}

	margin-bottom: 20px;

`;


export default Selection;