import { Vector3Tuple } from 'three';
import { useGlobalContext } from '../../../../context/contexts/global/GlobalContext';

const Hitbox = (props: props) => {
	const {showHitboxes} = useGlobalContext(); 

	return(
		<mesh
			position={props.position ? props.position : [0,0,0]} 							
			scale={props.scale ? props.scale : [0.1,0.1,0.05]}> 
			<meshBasicMaterial opacity={showHitboxes ? 0.5 : 0} color={'purple'} transparent/>
			<boxBufferGeometry/>
		</mesh>);

};

type props = {
    position?: Vector3Tuple,
    scale?: Vector3Tuple
	hitboxDebug?: boolean
}

export default Hitbox;