import { useProgress } from '@react-three/drei';
import styled from 'styled-components';
import Theme from '../style/theme';

const Loader = () => {
	const { progress } = useProgress();

	return <Container>			
		<StyledSpinner viewBox="0 0 50 50">
			<circle
				className="path"
				cx="25"
				cy="25"
				r="20"
				fill="none"
				strokeWidth="4"
			/>
		</StyledSpinner>
	</Container>;
};

// types

const Container  = styled.div`
	position: absolute;
	width: 100%;
	height:100%;
	background: black;
	z-index: 9001;
`;

const StyledSpinner = styled.svg`
	position: sticky;
	left: 50%;top:50%;
	transform: translate(-50%, -50%);
	animation: rotate 2s linear infinite;
	margin: -25px 0 0 -25px;
	width: 50px;
	height: 50px;
	
	& .path {
	  stroke: ${Theme.colors.primary};
	  stroke-linecap: round;
	  animation: dash 1.5s ease-in-out infinite;
	}
	
	@keyframes rotate {
	  100% {
		transform:  translate(-50%, -50%), rotate(360deg);
	  }
	}
	@keyframes dash {
	  0% {
		stroke-dasharray: 1, 150;
		stroke-dashoffset: 0;
	  }
	  50% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -35;
	  }
	  100% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -124;
	  }
	}
`;

export default Loader;