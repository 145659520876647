import { lazy } from 'react';
import { RoomCheck } from '../../RoomCheck';
import MoveSpot from '../../../controls/Movement/MoveSpot';

const roomKey = 'zolder';

const ZolderRoom = lazy(() => import('./ZolderRoom'));
const ZolderPuzzleElements = lazy(() => import('./PuzzleElements/ZolderPuzzleElements'));


export const Zolder = () => {

	// render
	return (
		<>
			<RoomCheck roomKey={roomKey}>
				<MoveSpot position={[-1.5,0.3,-0.25]}/>
				<ZolderRoom/>
				<ZolderPuzzleElements/>
			</RoomCheck>
		</>
	);
};