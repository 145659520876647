import styled from 'styled-components';
import { items } from '../../../../context/contexts/inventory/inventoryData';
import missingSprite from '../../../../assets/items/missing_sprite.png';
import { useMemo, useRef, useState } from 'react';
import { keyframes } from 'styled-components';
import { css } from 'styled-components';
import { useInventoryContext } from '../../../../context/contexts/inventory/InventoryContext';
import { useDataContext } from '../../../../context/contexts/data/DataContext';

export const InventoryItem = ({id}: InventoryItemProps) => {

	const {setDraggingItemID, setLastDragPosition} = useInventoryContext();
	const {getFileURL} = useDataContext();

	const sprite = useRef<HTMLDivElement>();
	const container = useRef<HTMLDivElement>();

	const [tracking, setTracking] = useState<boolean>(false);
	const [pointerPosition, setPointerPosition] = useState<{x: number, y: number}>();

	const updatePointerPositionForTouch = (e) => {
		setPointerPosition({x: e.touches[0].pageX, y: e.touches[0].pageY});
	};

	const updatePointerPositionForMouse = (e) => {
		setPointerPosition({x: e.pageX, y: e.pageY});
	};

	const startTrackingTouch = (e) => {
		setTracking(true);
		setDraggingItemID && setDraggingItemID(id);
		setPointerPosition({x: e.touches[0].pageX, y: e.touches[0].pageY});
		document.addEventListener('touchend', endTrackingTouch, {once: true});
		document.addEventListener('touchmove', updatePointerPositionForTouch);
		sprite.current && document.body.appendChild(sprite.current);

	};

	const startTrackingMouse = (e) => {
		setTracking(true);
		setDraggingItemID && setDraggingItemID(id);
		setPointerPosition({x: e.pageX, y: e.pageY});
		document.addEventListener('mouseup', endTrackingMouse, {once: true});
		document.addEventListener('mousemove', updatePointerPositionForMouse);
		sprite.current && document.body.appendChild(sprite.current);

	};

	const endTrackingTouch = (e) => {
		setTracking(false);
		document.removeEventListener('touchmove', updatePointerPositionForTouch);
		setDraggingItemID && setDraggingItemID(undefined);
		setLastDragPosition && setLastDragPosition({x: e.changedTouches[0].pageX, y: e.changedTouches[0].pageY});
		sprite.current && container.current && container.current.appendChild(sprite.current);
	};

	const endTrackingMouse = (e) => {
		setTracking(false);
		document.removeEventListener('mousemove', updatePointerPositionForMouse);
		setDraggingItemID && setDraggingItemID(undefined);
		setLastDragPosition && setLastDragPosition({x: e.pageX, y: e.pageY});
		sprite.current && container.current && container.current.appendChild(sprite.current);
	};
	const SpriteImg = useMemo(() => {
		return getFileURL && getFileURL(items[id] ? items[id] : '2b1c425f-45d8-4fd1-b5b7-d65cfdd2e881');
	},[]);
	return (
		<Container 
			onMouseDown={startTrackingMouse}
			onTouchStart={startTrackingTouch}
			ref={container}
		>
			<Sprite ref={sprite} imageURL={SpriteImg} position={pointerPosition} tracking={tracking} 
			/>
		</Container>
	);
};

// styled components

const Pickup = keyframes`
	from {
		transform: translate(-50%,-50%) scale(1);
	}
	to {
		transform: translate(-50%,-50%) scale(1.5);
	}
`;

const Return = keyframes`
	from {
		transform: scale(0);
	}
	to {
		transform: scale(1);
	}
`;


const Container = styled.div<{imageURL: string | null}>`
	height: 100px;
	width: 100px;
	/* background: green; */
	flex-shrink :0;
	position: relative;
`;

const Sprite = styled.div<SpriteProps>`

	position: absolute;
	left: ${p => {
		if(p.position && p.tracking) return `${p.position.x}px`; 
		else return 0;
	}};
	top: ${p => {
		if(p.position && p.tracking) return `${p.position.y}px`; 
		else return 0;
	}};
	height: 100px;
	width: 100px;

	background-image: url(${(p) => p.imageURL});
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;

	pointer-events: none!important;

	${ p => p.tracking ? css`
		animation: ${Pickup} .5s forwards;
		
	` : css`
		animation: ${Return} .5s;
	`
}
`;

// type

type SpriteProps = {
	imageURL: string | null
	position: {x: number, y: number}
	tracking: boolean
}

type InventoryItemProps = {
	id: number
}