import dialogueRequest from './dialogue';
import filesRequest from './files';
import HintPackagesRequest from './hintpackages';
import HPTranslationsRequest from './hintpackages_translations';

const requests = [
	dialogueRequest,
	filesRequest,
	HintPackagesRequest,
	HPTranslationsRequest,
];

export default requests;