import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Plausible from 'plausible-tracker';

// analytics

const { trackPageview } = Plausible({
	domain: 'arcadisvolwassenen.tqwi.nl',
	hashMode: true,
	// trackLocalhost: true
});

trackPageview();  

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);
