import { useEffect, useState } from 'react';
import { useDataContext } from '../../../../context/contexts/data/DataContext';
import { useGlobalContext } from '../../../../context/contexts/global/GlobalContext';
import { usePuzzleContext } from '../../../../context/contexts/puzzle/PuzzleContext';


const Hint = () => {
	const {activeHint, setActiveHint} = useGlobalContext();
	const {activeHintPackageId} = usePuzzleContext();
	const {data} = useDataContext();
	const [hints, setHints] = useState<string[]>([]);

	useEffect(() => {
		if(data && data.HintPackages && data.HintPackages_translations){
			if(activeHintPackageId && data.HintPackages[activeHintPackageId]){
				const hintTranslationId = data.HintPackages[activeHintPackageId].translations[0];
				const packageHints = data?.HintPackages_translations[hintTranslationId];
				setHints([packageHints.hint_1,packageHints.hint_2 && packageHints.hint_2,packageHints.hint_3 && packageHints.hint_3]);
			} else {
				//data.hint_packages[activeHintPackageId] doesnt exist so probably set activepackage to the wrong number
				const error = 'no hints found, probably wrong activeHintPackageId number';
				setHints([error,error,error]);
			}
		}
	},[activeHintPackageId]);

	return (
		<>
			{ activeHint &&
				hints[activeHint-1]
			}	
		</>
	);

};

//styled components

export default Hint;