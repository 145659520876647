import { HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';
import Theme from '../../../../style/theme';

const Content = (props: ContentProps) => {
	return ( 
		<Container {...props}>
			{props.children}
		</Container> 
	);
};

// sc

const Container = styled.div`

	position: relative;
  
	// Extra small screens
	@media (min-width: ${Theme.responsive.media.xs}){
		padding: ${Theme.responsive.whitespace.xs / 2}px;
	}

	// Small screens (tablets, big phones, small monitors).
	@media (min-width: ${Theme.responsive.media.sm}){
		padding: ${Theme.responsive.whitespace.sm / 2}px;
	}

	// Large screens
	@media (min-width: ${Theme.responsive.media.lg}){
		padding: ${Theme.responsive.whitespace.lg / 2}px;
	}

	// Extra large screens
	@media (min-width: ${Theme.responsive.media.xl}){
		padding: ${Theme.responsive.whitespace.xl / 2}px;
	}

	/* height: 100%; */
	flex-grow: 1;
	flex-shrink: 0;

	& > p {
		margin: 0;
	}

`;

interface ContentProps extends HTMLAttributes<HTMLDivElement> {
	
}
 
export default Content;