import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';
import { keyframes } from 'styled-components';
import { useGlobalContext } from '../../../context/contexts/global/GlobalContext';

// CameraFade is meant to emulate the closing of the eyes of the player.
// its actually just a black plane fading in as an UI element.

const CameraFade = () => {

	const { queuedRoom, setActiveRoom, setQueuedRoom, setCursorState } = useGlobalContext();
	const [fadeState, setFadeState] = useState<number>(0);

	// check wether a room is queued to be moved to.
	useEffect(() => {
		if(queuedRoom){
			setFadeState(1);
			setCursorState && setCursorState('moving');
		}
	},[queuedRoom]);

	// handle when the queued room can be loaded in.
	useEffect(() => {
		if(!queuedRoom || !setActiveRoom || !setQueuedRoom) return;

		if(fadeState === 2){
			setActiveRoom(queuedRoom);
			setQueuedRoom(undefined);
		}
	},[fadeState]);

	// render
	return (
		<>
			{fadeState !== 0 &&
				<Panel fadeState={fadeState} onAnimationEnd={() => {
					if(fadeState === 2 ){
						setFadeState(0); 
						setCursorState && setCursorState('pointer');
					} 
					else {
						setTimeout(() => {
							setFadeState(fadeState + 1);	
						}, 500);
					}
				}}/>
			} 
		</>
	);
};

// styled components

const fadeIn = keyframes`
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
`;

const fadeOut = keyframes`
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
`;

const Panel = styled.div<{fadeState: number}>`
	position: absolute;
	left:0;top:0;right:0;bottom:0;
	background: black;

	height: 100%;
	width: 100%;
	${ e => e.fadeState === 1 ? css`
		animation: ${fadeIn} 1s forwards;
	`
		: e.fadeState === 2 ? css`
		animation: ${fadeOut} 1s forwards;
	`: ''
}
`;

export default CameraFade;