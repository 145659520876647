import React, {useEffect, useState} from 'react';
import { useGlobalContext } from '../../context/contexts/global/GlobalContext';
	
export const data = 0;
export const accelerationSpeed = 1;

const Input = () => {
	
	useEffect(() => {
		document.addEventListener('keyup', keyUp);
		document.addEventListener('keydown', keyDown );

		return () => {
			document.removeEventListener('keyup', keyUp);
			document.addEventListener('keydown', keyDown );
		};
	}, []);

	const {onToggleDisplayConsole} = useGlobalContext();

	const [keyOne, setKeyOne] = useState<boolean>(false);
	const [keyTwo, setKeyTwo] = useState<boolean>(false);

	useEffect(() => {
		if(keyOne && keyTwo){
			onToggleDisplayConsole && onToggleDisplayConsole();
		}
	},[keyOne, keyTwo]);

	const keyDown = (e: { keyCode: any; }) => {
		switch(e.keyCode){
		//alt key
		case 18:
			setKeyOne(true);
			break;
		// Q key
		case 81:
			setKeyTwo(true);
			break;
		}	
	};

	const keyUp = (e: { keyCode: any; }) => {
		switch(e.keyCode){
		//ctrl key
		case 17:
			setKeyOne(false);
			break;
		// Q key
		case 81:
			setKeyTwo(false);
			break;
		}	
	};

	return (
		<></>
	);
};

    
export default Input;