import * as THREE from 'three';
import React, { useEffect, useRef, useState } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import { GroupProps } from '@react-three/fiber';
import model from './verbruiksmeter.gltf';
import { useGlobalContext } from '../../../../../../context/contexts/global/GlobalContext';
import { usePuzzleContext } from '../../../../../../context/contexts/puzzle/PuzzleContext';
import InteractiveElement from '../../../../elements/inspect or interact/InteractiveElement';
import Hitbox from '../../../../elements/inspect or interact/Hitbox';


const objectId = 'verbruiksmeter';

export const Verbruiksmeter = ({ ...props }: GroupProps & ownProps) => {
  
	const group = useRef<THREE.Group>(null);
	const { nodes, materials, animations } = useGLTF(model) as GLTFResult;
	const { actions } = useAnimations(animations, group);

	const {setTutorialStep, tutorialStep, setActiveDialogueMessage, activeScene} = useGlobalContext();
	const {changePuzzleData, puzzleData} = usePuzzleContext();
	const [activeIcon, setActiveIcon] = useState<number>(0);
	const [activeResult, setActiveResult] = useState<string>('');

	useEffect(() => {
		setActiveResult('');
	},[]);

	const confirm = () => {
		switch(props.puzzleId){
		case 'thermostaat':
			setresult(3,3,'puzzel1.jacuzzi.speler.m-1');
			break;		
		case 'gasfornuis':
			setresult(1,4,`puzzel1.gasfornuis.speler${activeScene === 2  ? '.s' : ''}-1`);
			break;
		case 'tv':
			setresult(5,5,`puzzel1.tv.speler.${activeScene === 1 ? 'm' : 's'}-1`);
			break;
		case 'wasmachine':
			setresult(4,3,'puzzel1.wasmachine.speler.s-1');
			break;
		}
	};

	const setresult = (activeicon: number, data: number, dialogue: string) =>{
		if(activeResult) return;
		if(activeIcon === activeicon){
			changePuzzleData && changePuzzleData(1,data,1);
			setActiveResult(activeicon === 3 || activeicon === 4  ? 'hoog' : 'laag');
			setTimeout(() => {
				setActiveDialogueMessage && setActiveDialogueMessage(dialogue);
			},1500);
		} else {
			setActiveResult('');
		}
	};

	return (
		<group ref={group} {...props} dispose={null}>
			<group name="Scene">
				<mesh onPointerDown={() => {if(setTutorialStep && tutorialStep === 7) setTutorialStep(7.1);}} 
					name="verbruiksmeter"
					castShadow
					receiveShadow
					geometry={nodes.verbruiksmeter.geometry}
					material={materials.verbruiksmeter}
				>
					<InteractiveElement 
						onClick={() => {if(activeIcon !==0){setActiveIcon(e => e-1);}else{setActiveIcon(6);}}}
						type={'click'}
						actions={actions}
						actionName={'verbruiksmeter_knop_links'}
					>
						<mesh
							name="knop_links"
							castShadow
							receiveShadow
							geometry={nodes.knop_links.geometry}
							material={materials.verbruiksmeter}
							position={[-0.114140585064888, -0.09999999403953552, 0.03435804694890976]}
						/>
						<Hitbox position={[-0.114140585064888, -0.09999999403953552, 0.03435804694890976]} scale={[0.1,0.15,0.1]}/>
					</InteractiveElement>
					<InteractiveElement 
						onClick={() => {if(activeIcon !==6){setActiveIcon(e => e+1);}else{setActiveIcon(0);}}}
						type={'click'}
						actions={actions}
						actionName={'verbruiksmeter_knop_rechts'}
					>
						<mesh
							name="knop_rechts"
							castShadow
							receiveShadow
							geometry={nodes.knop_rechts.geometry}
							material={materials.verbruiksmeter}
							position={[ 0.114140585064888, -0.09999999403953552, 0.03435804694890976]}
						/>
						<Hitbox position={[ 0.114140585064888, -0.09999999403953552, 0.03435804694890976]} scale={[0.1,0.15,0.1]}/>
					</InteractiveElement>
					<InteractiveElement 
						type={'click'}
						actions={actions}
						actionName={'verbruiksmeter_knop_midden'}
						onClick={confirm}
					>
						<mesh
							name="verbruiksmeter001"
							castShadow
							receiveShadow
							geometry={nodes.verbruiksmeter001.geometry}
							material={materials.verbruiksmeter}
							position={[0, -0.1, 0.03]}
						>
							{[1,2,3,4,5,6].map((number, index) => {
								return (
									<group key={index}>
										{activeIcon === number && <mesh 
											key={index}
											name={'icoon_0'+number+'verbruiksmeter'}
											castShadow
											receiveShadow
											geometry={nodes['icoon_0'+number+'_verbruiksmeter'].geometry}
											material={materials.Iconen}
											position={[0, 0, 0.01]}
											rotation={[Math.PI / 2, 0, 0]}
										/>}
									</group>);
							})}
						</mesh>
						{/* <Hitbox position={[0, -0.1, 0.03]} scale={[0.12,0.15,0.1]}/> */}
					</InteractiveElement>
					{['hoog', 'laag'].map((result, index) => {
						return(
							<group key={index}>
								{activeResult === result && <mesh
									
									name={'icoon_energie_'+result+'001'}
									castShadow
									receiveShadow
									geometry={nodes['icoon_energie_'+result+'001'].geometry}
									material={materials.Iconen}
									position={[-0.01, 0.1, 0.02]}
									rotation={[Math.PI / 2, 0, 0]}
								/>}
							</group>);
					})}
				</mesh>
			</group>
		</group>
	);
};

//types
type ownProps = {
	puzzleId?: string
}

type GLTFResult = GLTF & {
	nodes: {
		verbruiksmeter: THREE.Mesh;
		knop_links: THREE.Mesh;
		knop_rechts: THREE.Mesh;
		verbruiksmeter001: THREE.Mesh;
		icoon_01_verbruiksmeter: THREE.Mesh;
		icoon_02_verbruiksmeter: THREE.Mesh;
		icoon_03_verbruiksmeter: THREE.Mesh;
		icoon_04_verbruiksmeter: THREE.Mesh;
		icoon_05_verbruiksmeter: THREE.Mesh;
		icoon_06_verbruiksmeter: THREE.Mesh;
		icoon_energie_hoog001: THREE.Mesh;
		icoon_energie_laag001: THREE.Mesh;
	};
	materials: {
		verbruiksmeter: THREE.MeshStandardMaterial;
		Iconen: THREE.MeshStandardMaterial;
	};
	animations: GLTFAction[];
  };
  
  type ActionName =
	| 'verbruiksmeter'
	| 'icoon_energie_hoog.001'
	| 'icoon_energie_laag.001'
	| 'verbruiksmeter_knop_rechts'
	| 'verbruiksmeter_knop_links'
	| 'verbruiksmeter_knop_midden'
	| 'icoon_01_verbruiksmeter'
	| 'icoon_02_verbruiksmeter'
	| 'icoon_03_verbruiksmeter'
	| 'icoon_04_verbruiksmeter'
	| 'icoon_05_verbruiksmeter'
	| 'icoon_06_verbruiksmeter';
	
  interface GLTFAction extends THREE.AnimationClip {
	name: ActionName;
	}
 
useGLTF.preload(model);
