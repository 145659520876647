
const Theme = {
	colors:{
		primary: '#E4610F',
		primaryLighter: '#FBD5BA',
		neutralLightest: 'white',
		neutralDarkest: 'black',
		neutral: 'grey',
	},
	responsive:{
		media : {
			xs: '400px',
			sm: '600px',
			lg: '1025px',
			xl: '1440px',
		},
		whitespace : {
			xs: 20,
			sm: 35,
			lg: 50,
			xl: 100,
		},
		borderRadius: {
			xs: '5px',
			sm: '10px',
			lg: '10px',
			xl: '15px',
		}
	}
};

export default Theme;
