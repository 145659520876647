import { useEffect } from 'react';
import styled from 'styled-components';
import { useGlobalContext } from '../../../../context/contexts/global/GlobalContext';
import { useTextContext } from '../../../../context/contexts/text/TextContext';


const TutorialMessage = () => {
	
	const {activeTutorialMessage, setActiveTutorialMessage, tutorialStep, setTutorialStep} = useGlobalContext();
	const {t} = useTextContext();

	return (
		<>
			{activeTutorialMessage && t(activeTutorialMessage)}
		</>
	);
};

//styled compoentns

export default TutorialMessage;
