/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.8 meterkast_volwassenen.glb -t -k
*/

import * as THREE from 'three';
import React, { useEffect, useRef, useState } from 'react';
import { useAnimations, useGLTF } from '@react-three/drei';
import { GLTF } from 'three-stdlib';

import model from './meterkast_volwassenen.glb';
import { usePuzzleContext } from '../../../../../../context/contexts/puzzle/PuzzleContext';
import { useGlobalContext } from '../../../../../../context/contexts/global/GlobalContext';
import { useAudioContext } from '../../../../../../context/contexts/audio/AudioContext';
import InteractiveObject from '../../../../elements/inspect or interact/InteractiveObject';
import { Vector3 } from 'three';
import InteractiveElement from '../../../../elements/inspect or interact/InteractiveElement';
import Hitbox from '../../../../elements/inspect or interact/Hitbox';

type GLTFResult = GLTF & {
	nodes: {
		meterkast: THREE.Mesh;
		energiemeter: THREE.Mesh;
		display_meter: THREE.Mesh;
		energiemeter001: THREE.Mesh;
		icoon_abstract_01001: THREE.Mesh;
		icoon_abstract_02001: THREE.Mesh;
		icoon_abstract_fornuis001: THREE.Mesh;
		icoon_abstract_jacuzzi001: THREE.Mesh;
		icoon_abstract_tv001: THREE.Mesh;
		icoon_abstract_wasmachine001: THREE.Mesh;
		icoon_energie_1552001: THREE.Mesh;
		icoon_energie_413001: THREE.Mesh;
		icoon_energie_hoog002: THREE.Mesh;
		icoon_energie_jacuzzi001: THREE.Mesh;
		icoon_energie_laag002: THREE.Mesh;
		icoon_energie_totaal001: THREE.Mesh;
		icoon_energie_totaal002: THREE.Mesh;
		icoon_energie_tv001: THREE.Mesh;
		icoon_energie_wasmachine: THREE.Mesh;
		icoon_fornuis001: THREE.Mesh;
		icoon_jacuzzi001: THREE.Mesh;
		icoon_tv001: THREE.Mesh;
		icoon_wasmachine001: THREE.Mesh;
		knop_boven: THREE.Mesh;
		knop_boven001: THREE.Mesh;
		knop_boven002: THREE.Mesh;
		knop_onder: THREE.Mesh;
		knop_onder001: THREE.Mesh;
		knop_onder002: THREE.Mesh;
		Cube003: THREE.Mesh;
		icoon_energie_613001: THREE.Mesh;
		icoon_energie_gasfornuis001: THREE.Mesh;
		icoon_energie_totaal003: THREE.Mesh;
};
  materials: {
    meterkast: THREE.MeshStandardMaterial
    ['Iconen.001']: THREE.MeshStandardMaterial
    ['mat_meterkast_gasmeter.001']: THREE.MeshStandardMaterial
  }
  animations: GLTFAction[];
}

type GLTFActions = Record<ActionName, THREE.AnimationAction>;
interface GLTFAction extends THREE.AnimationClip {
name: ActionName;
}


const objectId = 'meterkast';

type ActionName = 'Empty.002' | 'meterkast_schuif' | 'energiemeter' | 'display_meter' | 'verbruiksmeter_knop_check' | 'icoon_abstract_01.001' | 'icoon_abstract_02.001' | 'icoon_abstract_fornuis.001' | 'icoon_abstract_jacuzzi.001' | 'icoon_abstract_tv.001' | 'icoon_abstract_wasmachine.001' | 'icoon_energie_1552.001' | 'icoon_energie_413.001' | 'icoon_energie_hoog.002' | 'icoon_energie_jacuzzi.001' | 'icoon_energie_laag.002' | 'icoon_energie_totaal.001' | 'icoon_energie_totaal.002' | 'icoon_energie_tv.001' | 'icoon_fornuis.001' | 'icoon_jacuzzi.001' | 'icoon_tv.001' | 'icoon_wasmachine.001' | 'energiemeter_knop_01' | 'energiemeter_knop_02' | 'energiemeter_knop_03' | 'energiemeter_knop_04' | 'energiemeter_knop_05' | 'energiemeter_knop_06' | 'icoon_energie_613.001' | 'icoon_energie_gasfornuis.001'

export default function Model(props: JSX.IntrinsicElements['group']) {
	const group = useRef<THREE.Group>(null);
	const { nodes, materials, animations } = useGLTF(model) as GLTFResult;
	const { actions } = useAnimations(animations, group);
	const {puzzleData, changePuzzleData, setPuzzleComplete} = usePuzzleContext();
	const {setActiveDialogueMessage, setCameraAction, activeScene} = useGlobalContext();
	const {playSound} = useAudioContext();

	const iconList = ['fornuis001', 'jacuzzi001', 'tv001', 'wasmachine001'];
	const [activeIcon, setActiveIcon] =useState<number>(1);

	const abstractList = ['01001', '02001', 'fornuis001', 'jacuzzi001', 'tv001', 'wasmachine001' ];
	const [activeAbstract, setActiveAbstract] =useState<number>(1);

	const [activeResult, setActiveResult] = useState<string>('laag');

	const [kwhResults, setkwhResults] = useState<boolean[]>([false,false,false,false]); //tv, jaccuzi, fornuis, all
	const [wrongInput, setWrongInput] = useState<boolean>(false);

	const [end, setEnd] = useState<boolean>(false);

	const confirm = () => {
		const icon = activeScene === 2 ? 4 : 2;
		const abstract = activeScene === 2 ? 6 : 4;
		//gasforneus
		if(activeIcon === 1 && activeAbstract === 3 && activeResult === 'laag'){
			setkwhResults([kwhResults[0],kwhResults[1],true,kwhResults[4]]);
		}
		//tv
		else if(activeIcon === 3 && activeAbstract === 5 && activeResult === 'laag'){	
			setkwhResults([true,kwhResults[1],kwhResults[2],kwhResults[4]]);
		} 		
		//jacuzzi/wasmachine
		else if(activeIcon === icon && activeAbstract === abstract && activeResult === 'hoog'){
			setkwhResults([kwhResults[0],true,kwhResults[2],kwhResults[4]]);
		} 
		//wrong
		else {
			setWrongInput(true);
			setTimeout(() => [
				setWrongInput(false)
			],2000);
		}
	};

	useEffect(() => {
		//complete
		if(kwhResults[0] && kwhResults[1] && kwhResults[2] && !end){
			setEnd(true);
			setkwhResults([true,true,true,true]);
			setPuzzleComplete && setPuzzleComplete(true);
			setTimeout(() => {
				setActiveDialogueMessage && setActiveDialogueMessage(`puzzel1.ingevuld.speler.${activeScene === 1 ? 'm' : 's'}-1`);
				setCameraAction && setCameraAction({type: 'back'});
				changePuzzleData && changePuzzleData(1,1,0); //disable meterkast
				changePuzzleData && changePuzzleData(1,2,1); //enable zolder staircase
			},1000);
		}
	},[kwhResults]);

	const inspect = () => {
		if(puzzleData && puzzleData[1].checks[1] === 1 && puzzleData && puzzleData[0].checks[1] === 0){ 
			changePuzzleData && changePuzzleData(0,1,1); 
			setActiveDialogueMessage && setActiveDialogueMessage(`puzzel1.meterkast.${activeScene === 1 ? 'modi' : 'sandra'}-1`);
		}	
	};

	return (
		<group {...props} dispose={null} ref={group}>
			<InteractiveObject 
				onClick={inspect}
				inspectData={{				
					cameraPosition: new Vector3(0,1.3,.3),
					newTarget: new Vector3(0,1.3,0),
					localPositions: true,
					layer: 0,
					objectId: objectId,
					horizontalRestrictions: [1.5, -1.5],
					//verticalRestrictions: [1,1]
				}}
				lockedUntil={{puzzleId:1,checkId:1,checkValue:1}}
			>	
				<mesh name="Cube003" geometry={nodes.Cube003.geometry} material={materials['mat_meterkast_gasmeter.001']} position={[0, 1.447, -0.11]}>
					{ kwhResults[2] && <mesh name="icoon_energie_613001" geometry={nodes.icoon_energie_613001.geometry} material={materials['Iconen.001']} position={[0.028, 0.005, 0.026]} rotation={[Math.PI / 2, 0, 0]} scale={[0.005, 0, 0.005]} /> }
					<mesh name="icoon_energie_gasfornuis001" geometry={nodes.icoon_energie_gasfornuis001.geometry} material={materials['Iconen.001']} position={[0.041, 0.023, 0.029]} rotation={[Math.PI / 2, 0, 0]} scale={0.008} />
					{/* <mesh name="icoon_energie_totaal003" geometry={nodes.icoon_energie_totaal003.geometry} material={materials['Iconen.001']} position={[0.062, 0.006, 0.026]} rotation={[Math.PI / 2, 0, 0]} scale={[0.006, 0, 0.006]} /> */}
				</mesh>
				<mesh name="meterkast" geometry={nodes.meterkast.geometry} material={materials.meterkast}>
					<mesh name="energiemeter" geometry={nodes.energiemeter.geometry} material={materials.meterkast} position={[0.07, 1.3, -0.07]}>
						{kwhResults[1] && <mesh name="icoon_energie_1552001" geometry={nodes.icoon_energie_1552001.geometry} material={materials['Iconen.001']} position={[0.121, -0.001, 0.0235]} rotation={[Math.PI / 2, 0, 0]} scale={[0.004, 0, 0.004]} />}
						{kwhResults[0] && <mesh name="icoon_energie_413001" geometry={nodes.icoon_energie_413001.geometry} material={materials['Iconen.001']} position={[0.12, 0.016, 0.0235]} rotation={[Math.PI / 2, 0, 0]} scale={[0.004, 0, 0.004]} />}
						{kwhResults[3] && <mesh
							name="icoon_energie_totaal001"
							castShadow
							receiveShadow
							geometry={nodes.icoon_energie_totaal001.geometry}
							material={materials['Iconen.001']}
							position={[0.12, 0.033, 0.024]}
							rotation={[Math.PI / 2, 0, 0]}
							scale={[0.006, 0, 0.006]}
						/> }
						<mesh
							name="icoon_energie_totaal002"
							castShadow
							receiveShadow
							geometry={nodes.icoon_energie_totaal002.geometry}
							material={materials['Iconen.001']}
							position={[0.16, 0.033, 0.022]}
							rotation={[Math.PI / 2, 0, 0]}
							scale={[0.006, 0, 0.006]}
						/>
						<mesh
							name="display_meter"
							receiveShadow
							geometry={nodes.display_meter.geometry}
							material={nodes.display_meter.material}
							position={[0.12, 0.033, 0.02]}
						/>
						<mesh
							name="icoon_energie_tv001"
							castShadow
							receiveShadow
							geometry={nodes.icoon_energie_tv001.geometry}
							material={materials['Iconen.001']}
							position={[0.06, 0.013, 0.025]}
							rotation={[Math.PI / 2, 0, 0]}
							scale={0.006}
						/>
						{ activeScene !== 1 ?
							<mesh
								name="icoon_energie_wasmachine"
								castShadow
								receiveShadow
								geometry={nodes.icoon_energie_wasmachine.geometry}
								material={materials['Iconen.001']}
								position={[0.06, -0.002, 0.024]}
								rotation={[Math.PI / 2, 0, 0]}
								scale={[0.009, 0.009, 0.011]}
							/> :
							<mesh
								name="icoon_energie_jacuzzi001"
								castShadow
								receiveShadow
								geometry={nodes.icoon_energie_jacuzzi001.geometry}
								material={materials['Iconen.001']}
								position={[0.06, -0.002, 0.024]}
								rotation={[Math.PI / 2, 0, 0]}
								scale={[0.009, 0.009, 0.011]}
							/>
						}
						{['hoog', 'laag'].map((result, index) => {
							return(
								<group key={index}>
									{activeResult === result && <mesh
										key={index}
										name={'icoon_energie_'+result+'002'}
										castShadow
										receiveShadow
										geometry={nodes['icoon_energie_'+result+'002'].geometry}
										material={materials['Iconen.001']}
										position={[-0.015, 0.001, 0.04]}
										rotation={[Math.PI / 2, 0, 0]}
										scale={0.01}
									/>}
								</group>);
						})}
						{abstractList.map((abstract, index) => {
							return (
								<group key={index}>
									{abstractList[activeAbstract-1] === abstract && <mesh
										key={index}
										name={'icoon_abstract_'+abstract}
										castShadow
										receiveShadow
										geometry={nodes['icoon_abstract_'+abstract].geometry}
										material={materials['Iconen.001']}
										position={[-0.085, 0.002, 0.04]}
										rotation={[Math.PI / 2, 0, 0]}
										scale={0.047}
									/>}
								</group>);
						})}		
						{iconList.map((icon, index) => {
							return (
								<group key={index}>
									{iconList[activeIcon-1] === icon && <mesh
										key={index}
										name={'icoon_'+icon}
										castShadow
										receiveShadow
										geometry={nodes['icoon_'+icon].geometry}
										material={materials['Iconen.001']}
										position={[-0.155, 0.003, 0.04]}
										rotation={[Math.PI / 2, 0, 0]}
										scale={0.02}
									/>}
								</group>);
						})}
						<InteractiveElement 
							type={'click'}
							actions={actions}
							actionName={'energiemeter_knop_01'}
							onClick={() => {if(activeIcon !=4){setActiveIcon(e => e+1);}else{setActiveIcon(1);}}}
						>
							<mesh name="knop_boven" geometry={nodes.knop_boven.geometry} material={materials.meterkast} position={[-0.155, 0.052, 0.032]} />
						</InteractiveElement>
						<InteractiveElement 
							type={'click'}
							actions={actions}
							actionName={'energiemeter_knop_02'}
							onClick={() => {if(activeAbstract !=6){setActiveAbstract(e => e+1);}else{setActiveAbstract(1);}}}
						>
							<mesh name="knop_boven001" geometry={nodes.knop_boven001.geometry} material={materials.meterkast} position={[-0.085, 0.052, 0.032]} />
						</InteractiveElement>
						<InteractiveElement 
							type={'click'}
							actions={actions}
							actionName={'energiemeter_knop_03'}
							onClick={() => setActiveResult(e => e === 'hoog' ? 'laag' : 'hoog')}
						>
							<mesh name="knop_boven002" geometry={nodes.knop_boven002.geometry} material={materials.meterkast} position={[-0.015, 0.052, 0.032]} />
						</InteractiveElement>
						<InteractiveElement 
							type={'click'}
							actions={actions}
							actionName={'energiemeter_knop_04'}
							onClick={() => {if(activeIcon !=1){setActiveIcon(e => e-1);}else{setActiveIcon(4);}}}
						>
							<mesh name="knop_onder" geometry={nodes.knop_onder.geometry} material={materials.meterkast} position={[-0.155, -0.049, 0.032]} rotation={[0, 0, Math.PI]} />
						</InteractiveElement>
						<InteractiveElement 
							type={'click'}
							actions={actions}
							actionName={'energiemeter_knop_05'}
							onClick={() => {if(activeAbstract !=1){setActiveAbstract(e => e-1);}else{setActiveAbstract(6);}}}
						>
							<mesh name="knop_onder001" geometry={nodes.knop_onder001.geometry} material={materials.meterkast} position={[-0.085, -0.049, 0.032]} rotation={[0, 0, Math.PI]} />
						</InteractiveElement>
						<InteractiveElement 
							type={'click'}
							actions={actions}
							actionName={'energiemeter_knop_06'}
							onClick={() => setActiveResult(e => e === 'hoog' ? 'laag' : 'hoog')}
						>
							<mesh name="knop_onder002" geometry={nodes.knop_onder002.geometry} material={materials.meterkast} position={[-0.015, -0.049, 0.032]} rotation={[0, 0, Math.PI]} />
						</InteractiveElement>
						<InteractiveElement 
							onClick={confirm}
							type={'click'}
							actions={actions}
							actionName={'verbruiksmeter_knop_check'}
						>
							<mesh
								name="energiemeter001"
								castShadow
								receiveShadow
								geometry={nodes.energiemeter001.geometry}
								material={materials.meterkast}
								position={[0.179387, 0.007, 0.022667]}
							>
								{wrongInput && <meshBasicMaterial color={'red'}/>}
							</mesh>
							<Hitbox position={[0.179387, 0.007, 0.022667]} scale={[0.04,0.05,0.04]}/>
						</InteractiveElement>
					</mesh>
				</mesh>
			</InteractiveObject>
		</group>
	);
}

useGLTF.preload(model);
