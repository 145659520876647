import React, { Suspense } from 'react';
import Console from './components/Console';
import Input from './components/Input/Input';
import ThreeCanvas from './components/Three/ThreeCanvas';
import ContextProvider from './context/ContextProvider';
import CustomThemeProvider from './style/CustomThemeProvider';
import MovementContext from './components/Three/controls/Movement/CameraMovement';

import FloorPointer from './components/Three/controls/Movement/FloorPointer';
import GameFlowManager from './components/GameFlowManager/GameFlowManager';
import SceneManager from './components/Three/scenes/SceneManager';
import Interface from './components/interface/Interface';
import TutorialManager from './components/Tutorial';
import Loader from './components/Loader';
import ForceLandscape from './components/ForceLandscape';

function App() {
	return (
		<ContextProvider>
			<CustomThemeProvider>
				<Console/>
				<Suspense fallback={<Loader/>}>
					<ThreeCanvas>
						{/* <Stats/> */}
						<MovementContext>
							<SceneManager/>
						</MovementContext>
					</ThreeCanvas>
				</Suspense>
				<TutorialManager/>
				<Interface/>
				<GameFlowManager/>
				<Input/>
				<ForceLandscape/>			
			</CustomThemeProvider>
		</ContextProvider>

	);
}

export default App;
