import { useEffect, useState } from 'react';
import { ConsoleLabel, ConsoleRow } from '.';
import { useGlobalContext } from '../../../context/contexts/global/GlobalContext';

const Scenes = () => {
	const {activeScene, setActiveScene, activeRoom, setActiveRoom} = useGlobalContext();

	const [selectValueScene, setSelectValueScene] = useState<number>(activeScene ? activeScene : 0);
	const [selectValueRoom, setSelectValueRoom] = useState<string>(activeRoom ? activeRoom : '');


	useEffect(() => {

		if(!activeScene) return;

		if(selectValueScene !== activeScene)
			setSelectValueScene(activeScene);

	},[activeScene]);

	useEffect(() => {
		if(!setActiveScene) return;

		setActiveScene(selectValueScene);

	},[selectValueScene]);

	useEffect(() => {

		if(!activeRoom) return;

		if(selectValueRoom !== activeRoom)
			setSelectValueRoom(activeRoom);

	},[activeScene]);


	useEffect(() => {
		if(!setActiveRoom) return;

		setActiveRoom(selectValueRoom);

	},[selectValueRoom]);

	return (
		<>

			<ConsoleRow>
				<ConsoleLabel>Scene management</ConsoleLabel>
				<select value={selectValueScene} onChange={(e) => setSelectValueScene(parseInt(e.target.value))}>
					<option value="0">0</option>
					<option value="1">1</option>
					<option value="2">2</option>
				</select>
			</ConsoleRow>
			<ConsoleRow>
				<ConsoleLabel>Room management</ConsoleLabel>
				<input value={selectValueRoom} onChange={(e) => setSelectValueRoom(e.target.value)}>

				</input>
			</ConsoleRow>
		</>
	);
};

export default Scenes;