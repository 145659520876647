import { time } from 'console';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { keyframes } from 'styled-components';
import { css } from 'styled-components';
import Theme from '../../../../style/theme';
import { DialogueProps } from './Dialogue';
import { DialogueHeader } from './DialogueHeader';
import { Message } from './DialogueWindow';

export const DialogueRemark = (props: DialogueProps & {pushed: boolean, setPushed: () => void}) => {

	const [exit, setExit] = useState<boolean>(false);
	const [render, setRender] = useState<boolean>(false);

	const timeout = useRef<any>();

	useEffect(() => {
		if(props.pushed){
			setRender(false);
		}
		else{
			setRender(true);
		}
	},[]);

	useEffect(() => {
		if(!props.message) return () => {
			clearTimeout(timeout.current);
		};
		else if(!props.pushed){
			if(timeout.current) clearTimeout(timeout.current);
			timeout.current = setTimer();
			setRender(true);
		}
		else if(props.pushed){
			setRender(false);
		}
		return () => {
			clearTimeout(timeout.current);
		};
	},[props.message]);

	const endTimer = () =>{
		setExit(true);
		props.setPushed();
	};

	const setTimer = () => {
		setExit(false);
		return setTimeout(() => {
			endTimer();
		}, 8000);
	};
	
	useEffect(() => {
		//
	},[exit]);

	return (
		<Container>
			{ props.message && render && 
				<AnimationContainer exit={exit}>
					<DialogueHeader message={props.message}/>
					<StyledMessage sender={1}>{props.message.translation.text}</StyledMessage>
				</AnimationContainer>
			}
		</Container>
	);
};

// styled components

const entrance = keyframes`
	from {
		transform: scale(0);
	}
	to {
		transform: scale(1);
	}
`;

const exit = keyframes`
	from {
		transform: scale(1);
	}
	to {
		transform: scale(0);
	}
`;

const Container = styled.div<{exit: boolean}>`
    height:100%;width:100%;
	display: flex;
	align-items: flex-end;

	pointer-events: none!important;

	& > * {
		pointer-events: all;
	}
`;

const StyledMessage = styled(Message)`
	background-color: ${Theme.colors.neutralLightest};
	border-radius:  0 0 10px 10px;
	margin:0;
`;

const AnimationContainer = styled.div<{exit: boolean}>`
 	position: absolute;
    width:100%;
	transform-origin: 100% 100%;
	${p => !p.exit ? css`
		animation: ${entrance} forwards ease-in-out .4s;
	` : css`
		animation: ${exit} forwards ease-in-out .4s;
	`
}


	pointer-events: none;

& > * {
	pointer-events: all;
}
`;