import * as THREE from 'three';
import React, { useEffect, useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import { GroupProps } from '@react-three/fiber';
import verbruiksmeter from '../../../scenes/rooms/Hal/PuzzleElements/verbruiksmeter.gltf';

export const VerbruiksmeterIndicator = ({ ...props }: GroupProps) => {
  
	const group = useRef<THREE.Group>(null);
	const { nodes, materials } = useGLTF(verbruiksmeter) as GLTFResult;

	return (
		<group ref={group} {...props} dispose={null}>
			
			<group name="Scene">
				<mesh
					name="verbruiksmeter"
					castShadow
					receiveShadow
					geometry={nodes.verbruiksmeter.geometry}
					//material={materials.verbruiksmeter}
				>				
					<meshBasicMaterial color={'orange'} opacity={0.7} transparent/>	
					<mesh
						name="knop_links"
						castShadow
						receiveShadow
						geometry={nodes.knop_links.geometry}
						//material={materials.verbruiksmeter}
						position={[-0.11, -0.1, 0.03]}
					>					
						<meshBasicMaterial color={'orange'} opacity={0.7} transparent/>	
					</mesh>
					<mesh
						name="knop_rechts"
						castShadow
						receiveShadow
						geometry={nodes.knop_rechts.geometry}
						//material={materials.verbruiksmeter}
						position={[0.11, -0.1, 0.03]}
					>						
						<meshBasicMaterial color={'orange'} opacity={0.7} transparent/>	
					</mesh>
					<mesh
						name="verbruiksmeter001"
						castShadow
						receiveShadow
						geometry={nodes.verbruiksmeter001.geometry}
						//material={materials.verbruiksmeter}
						position={[0, -0.1, 0.03]}
					>
						<meshBasicMaterial color={'orange'} opacity={0.7} transparent/>	
					</mesh>
				</mesh>
			</group>
		</group>
	);
};

//types

type GLTFResult = GLTF & {
  nodes: {
	verbruiksmeter: THREE.Mesh;
	knop_links: THREE.Mesh;
	knop_rechts: THREE.Mesh;
	verbruiksmeter001: THREE.Mesh;
  }
  materials: {
    verbruiksmeter: THREE.MeshStandardMaterial
  }
  animations: GLTFAction[];

}

type ActionName = 'verbruiksmeter' | 'circel_knop' | 'verbruiksmeter_knop_links' | 'verbruiksmeter_knop_rechts'
interface GLTFAction extends THREE.AnimationClip {
	name: ActionName;
	}
 
useGLTF.preload(verbruiksmeter);
