export const data : PuzzleData = [
	// puzzle 0 - tutorial
	{
		hintPackageId: 2,
		checks: [
			0,//suitcase availability
			0,//door availabilities
			0,//hint button
			0,//interactibles button
			0,//inventory
		]
	},
	// puzzle 1 - puzzle verbruik
	{
		hintPackageId: 1,
		checks: [
			0,//meterkast door availability
			0,//meterkast availability
			0,//zolderavailability
			0,//jacuzzi icon correct
			0,//gasfornuis icon correct
			0,//tv icon correct
		]
	},
	// puzzle 2 - puzzle zolder
	{
		hintPackageId: 3,
		checks: [
			0,//wieken placed
			0,//interacted on drawer
			0,//table drawer open
			0,//pijpje placed
			0,//inspecting fabriek: gaskraan available
			0,//inspected gaskraan area
			0,//gaskraan placed
			0,//batterij status, 3 = hendel available
		]
	},
	// puzzle 3 - puzzle kelder
	{
		hintPackageId: 5,
		checks: [
			0,//luik:zoomed in,
			0,//hendel placed
			0,//1 = 3 pieces grabbed, 0 = 0 pieces grabbed
			0,//item part 1 removed
			0,//item part 2 removed
			0,//item part 3 removed
			0,//0 = cant print, 1 = can print
			0,//placed fornuis model 
		]
	},
	// puzzle 4 - puzzle grafiek
	{
		hintPackageId: 4,
		checks: [
			0, // de deur naar de art room is beschikbaar
			0, // er is een graph gekozen op de console
			0, // plak 1 staat goed
			0, // plak 2 staat goed
			0, // plak 3 staat goed
			0, // plak 4 staat goed
			0, // plak 5 staat goed
			0, // plak 6 staat goed
			0, // plak 7 staat goed
			0, // simulatie moet doorlopen worden (9)
			0, // simulatie succesvol
			0,//voordeur opened
		]
	},
];

// types

export type PuzzleData = PuzzleDataRecord[];

export type PuzzleDataRecord = {
    hintPackageId: number,
    checks: number[],
}
