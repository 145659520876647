import styled from 'styled-components';
import { Button } from '../../../Buttons/Buttons';
import { useGlobalContext } from '../../../../context/contexts/global/GlobalContext';
import Theme from '../../../../style/theme';
import exit from '../../../../assets/images/logout.png';
import { usePuzzleContext } from '../../../../context/contexts/puzzle/PuzzleContext';


export const ExitButton = () => {

	const {setGameState} = useGlobalContext();
	const {puzzleComplete, activePuzzle} = usePuzzleContext();

	const onExit = () => {
		if(puzzleComplete) {setGameState && setGameState(activePuzzle === 0 ? 3 : 4);return;}
		if(confirm('weet je zeker dat je naar het eindscherm wilt gaan?')){
			setGameState && setGameState(activePuzzle === 0 ? 3 : 4);
		}
	};

	return (
		<Container>
			<StyledButton iconURL={exit} onClick={onExit}></StyledButton>
		</Container>
	);
};

// styled components

const Container = styled.div`
	padding: 0;
	height: 50px;
	width: max-content;
	flex-shrink:0;
	margin-top: 0!important;
	border-radius: ${Theme.responsive.borderRadius.sm};
	background-color: ${Theme.colors.primaryLighter};
	position: relative;
	pointer-events: none;
	font-weight: bold;

	
	@media (min-width: ${Theme.responsive.media.xs}){
			margin: ${Theme.responsive.whitespace.xs  / 2}px;
			font-size: 1rem;
		}

		// Small screens (tablets, big phones, small monitors).
		@media (min-width: ${Theme.responsive.media.sm}){
			margin: ${Theme.responsive.whitespace.sm / 2}px;
			font-size: 1.2rem;
		}

		// Large screens
		@media (min-width: ${Theme.responsive.media.lg}){
			margin: ${Theme.responsive.whitespace.lg / 2}px;
			font-size: 1.4rem;
		}

		// Extra large screens
		@media (min-width: ${Theme.responsive.media.xl}){
			margin: ${Theme.responsive.whitespace.xl / 3}px;
			font-size: 1.4rem;

		}
`;

const StyledButton = styled(Button)`
	pointer-events: all;

	right:0;
	top:0;
	padding: 0;
	/* transform: translateX(110%); */
	height: 100%;
	aspect-ratio: 1/1;
	width: unset;

	& > div{
		inset: 6px;
	}

`;

