import { useEffect, useState, useMemo } from 'react';
import { act } from 'react-dom/test-utils';
import styled from 'styled-components';
import { useDataContext } from '../../../../context/contexts/data/DataContext';
import { useGlobalContext } from '../../../../context/contexts/global/GlobalContext';
import { useTextContext } from '../../../../context/contexts/text/TextContext';
import Theme from '../../../../style/theme';
import { DialogueProps } from './Dialogue';

const avatarURLS = { // 0 = neutral, 1 = pensive, 2 = agitated, 3 = happy
	'2' : [ // modie avatars
		'bbe4898d-88a0-421d-9312-6b3a36e97541',
		'43218957-8289-465c-996e-a05cb5ab3efb',
		'71fbf330-587b-4aef-b35a-8e2a6e997c73',
		'c356fbf5-7aee-4cb2-becd-8c55f13a219b'
	],
	'1' : [ // sandra avatars
		'987d8f39-ed6a-47f7-949c-ab3d5a8b457b',
		'ff9b455d-0534-4805-8ba9-0d08632509d7',
		'cee451ef-f152-4630-a49f-ab61921f681b',
		'9a676e0c-88d3-4789-b0cc-56664a4f4c51'
	]
};

export const DialogueHeader = (props : DialogueProps) => {

	const {activeScene} = useGlobalContext();
	const {getFileURL} = useDataContext();

	const {t} = useTextContext();

	const avatarURL = useMemo<string>(() => {
		if(!getFileURL )return '';

		if(props.message && activeScene){
			return getFileURL(avatarURLS[activeScene][props.message.object.mood - 1]);
		}
		else if(activeScene){
			return getFileURL(avatarURLS[activeScene][0]);
		}
		else{
			return getFileURL(avatarURL[1][0]);
		}
	},[props.message]);

	return(
		<Container>
			<AvatarContainer>
				<Avatar imageURL={avatarURL}/>
			</AvatarContainer>
			<div>{t(`bewoner${activeScene}.naam`)}</div>
		</Container>
	);
};

// styled components

const Container = styled.div`
	background: ${Theme.colors.primary};
	margin-bottom: auto;
	display: flex;
	color: ${Theme.colors.neutralLightest};
	border-radius: 10px 10px 0 0;
	padding: 10px;
	font-weight: bold;
`;

const Avatar = styled.div<{imageURL : string}>`

	position: absolute;
	left:0; right:0;
	bottom: -10px;
	aspect-ratio: 1/1;

	background-image: url(${p => p.imageURL});
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
`;

const AvatarContainer = styled.div`
	position: relative;
	width: 200px;
`;