import { lazy } from 'react';
import { RoomCheck } from '../../RoomCheck';

const roomKey = 'woonkamer';

const WoonkamerShell = lazy(() => import('./WoonkamerShell'));
const WoonkamerInterior01 = lazy(() => import('./WoonkamerInterior01'));
const WoonkamerInterior02 = lazy(() => import('./WoonkamerInterior02'));
const Televisie = lazy(() => import('./PuzzleElements/Televisie'));
 
export const Woonkamer = () => {

	// render
	return (
		<>
			{/* Modie's Room */}
			<RoomCheck roomKey={roomKey} scene={1}>
				<WoonkamerInterior01/>
			</RoomCheck>
			{/* Sandra's Room */}
			<RoomCheck roomKey={roomKey} scene={2}>
				<WoonkamerInterior02/>
			</RoomCheck>
			{/* Shell */}
			<RoomCheck roomKey={roomKey}>
				<WoonkamerShell/>
				{/* Puzzle Objects */}
				<Televisie
					position={[2.68,0.7,1.7]}
					rotation={[-Math.PI ,  Math.PI * 1.5, -Math.PI]}
				/>
			</RoomCheck>
		</>
	);
};