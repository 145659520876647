import { directus } from '../../../../utilities/directus';

const HintPackagesRequest = async() => {

	const response = {};

	await directus.items('HintPackages').readByQuery({limit: -1, meta: '*'}).then((data) => {
		if(!data.data) return;

		data.data.map((value) => {
			if(!value || !value.id) return;
			response[value.id] = value;
		});
	});

	return {
		response : response,
		collection: 'HintPackages'
	};
};

export default HintPackagesRequest;