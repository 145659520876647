import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { keyframes } from 'styled-components';
import { useAudioContext } from '../../../../context/contexts/audio/AudioContext';
import { useDataContext } from '../../../../context/contexts/data/DataContext';
import { useGlobalContext } from '../../../../context/contexts/global/GlobalContext';
import Theme from '../../../../style/theme';
import { DialogueProps } from './Dialogue';
import { DialogueHeader } from './DialogueHeader';

export const DialogueWindow = (props: DialogueProps) => {

	const {data} = useDataContext();
	const {setActiveDialogueMessage} = useGlobalContext();

	const scrollContainer = useRef<any>(null);

	const followuptimeout = useRef<any>();

	useEffect(() => {
		if(scrollContainer.current){
			scrollContainer.current.scrollTo(0, scrollContainer.current.scrollHeight);
		}

		return () => {
			clearTimeout(followuptimeout.current);
		};

	},[]);

	useEffect(() => {

		if(!props.message) return;

		setTimeout(() => {
			if(scrollContainer.current){
				scrollContainer.current.scrollTo(0, scrollContainer.current.scrollHeight);
			}
		},100);

		if(props.message.object.follow_up_message){
			if(followuptimeout.current){
				clearTimeout(followuptimeout.current);
			}
			followuptimeout.current = setTimeout(() => {
				if(!props.message) return;
				setActiveDialogueMessage && setActiveDialogueMessage(props.message.object.follow_up_message);
				
			}, props.message.object.follow_up_delay ? props.message.object.follow_up_delay : 2000);
		}
		
	},[props.message]);

	return (
		<Container style={{pointerEvents: props.conversation ? 'all' : 'none'}}>
			<DialogueHeader message={props.message}/>
			<SubContainer>
				<ScrollContainer ref={scrollContainer}>
					<div>
						{props.history?.map((historyItem, index) => {
							if(data && data.dialogue && data.dialogue[historyItem]){
								return <Message key={`message-${data.dialogue[historyItem]}-${index}`} sender={data.dialogue[historyItem].object.sender}>
									{data.dialogue[historyItem].translation.text}
								</Message>;
							}
							else if(historyItem === 'spacer'){
								return <Spacer/>;
							}
							else{
								return '';
							}
						})}
					</div>
				</ScrollContainer>
			</SubContainer>
		</Container>

	);
};

// styled components

const scaleOpen = keyframes`
	from {
		transform: scale(0);
	}
	to {
		transform: scale(1);
	}
`;

const Container = styled.div`
	position: absolute;
	inset:0;
	margin-top: 200px;
	display: flex;
	flex-direction: column;
	transform-origin: 100% 100%;
	animation: ${scaleOpen} forwards .4s;
`;

const SubContainer = styled.div`
	flex-grow: 1;
	position: relative;
`;

const ScrollContainer = styled.div`

	position: absolute;
	inset:0;

    background-color:green;
	overflow-y: auto;
	overflow-x: hidden;

	flex-grow: 1;
	display: flex;
	flex-direction: column;

	background-color: rgba(0,0,0,0.65);

	& > div {
		display: flex;
		flex-direction: column;
		margin-top: auto;
		gap: 10px;
		padding: 10px;
	}

`;

const Spacer = styled.div`
	height: 4px;
	background-color: ${Theme.colors.primary};
`;

export const Message = styled.div`
	position: relative ;
	background-color: ${p => p.sender === 1 ? Theme.colors.neutralLightest : Theme.colors.primaryLighter};
	margin-right: ${p => p.sender === 1 ? '20%' : '10px'};
	margin-left: ${p => p.sender === 2 ? '20%' : '10px'};
	border-radius: 10px;
	padding: 10px;

	&:after{
		content: '';
		position: absolute;
		left: ${p => p.sender === 1 ? '-15px' : ''};
		right: ${p => p.sender === 2 ? '-15px' : ''};
		bottom:10px;
		width:0;height:0;
		border-top: 5px solid transparent;
		border-right: ${p => p.sender === 1 ? `10px solid ${Theme.colors.neutralLightest}` : '5px solid transparent' };
		border-left: ${p => p.sender === 1 ? '5px solid transparent' : `10px solid ${Theme.colors.primaryLighter}`};
		border-bottom: 5px solid transparent;
	}
`;

// types 

