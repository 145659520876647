import { GroupProps, useThree } from '@react-three/fiber';
import { Children, ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Group, Mesh, Raycaster, Vector2, Vector3Tuple } from 'three';
import { Select } from '@react-three/postprocessing';
import { useInventoryContext } from '../../../../context/contexts/inventory/InventoryContext';
import { useCameraMovementContext } from '../../controls/Movement/CameraMovement';
import { InteractiveObjectContext } from '../inspect or interact/InteractiveObject';
import { PointerHandler } from '../inspect or interact/PointerHandler';

export const InventoryObject = (props: InventoryObjectProps & GroupProps) => {

	const {draggingItemID, lastDraggingItemID, lastDragPosition, removeFromInventory, addToInventory} = useInventoryContext();
	const {objectId} = useContext(InteractiveObjectContext);
	const {inspectingObject} = useCameraMovementContext();

	const {camera} = useThree();
    
	const [placed, setPlaced] = useState<boolean>(props.initialyPlaced);

	const [firstPlace, setFirstPlace] = useState<boolean>(false);
	const [firstObtain, setFirstObtain] = useState<boolean>(false);

	const ref = useRef<Group>(null);
	const hitbox = useRef<Mesh>(null);


	const inspectCheck = useMemo<boolean>(() => {	
		return props.overwriteBlock ? true : objectId === inspectingObject;
	},[objectId, props.overwriteBlock, inspectingObject]);

	const indicator = useMemo<boolean>(() => {
		if((!props.replace && firstObtain) || !inspectCheck ) return false;
		
		return props.placeable && props.itemId === draggingItemID ? true : false;
	},[draggingItemID, objectId, firstPlace]);

	useEffect(() => {	
		cast();
	},[lastDragPosition]);

	const raycast = new Raycaster();

	const cast = () => {

		if( !props.placeable || !lastDragPosition || (!props.replace && firstPlace) || !inspectCheck || props.itemId != lastDraggingItemID) return;

		const normalizedValues = {
			x: (lastDragPosition.x / window.innerWidth) * 2 - 1, 
			y: -(lastDragPosition.y / window.innerHeight) * 2 + 1, 
		};

		raycast.setFromCamera(new Vector2(normalizedValues.x, normalizedValues.y), camera);

		if(!hitbox.current) return;
		const intersect = raycast.intersectObject(hitbox.current);

		if(intersect.length > 0 && intersect[0].object.uuid === hitbox.current.uuid){
			setPlaced(true);
			if(props.onPlace) props.onPlace();
			
			if(!firstPlace)	setFirstPlace(true);
			removeFromInventory && removeFromInventory(props.itemId);
		}

	};

	// goes off when the player clicks on the object
	const interact = () => {
		if( !props.obtainable ||!placed || !props.reobtain && firstPlace || !inspectCheck) return;
		setPlaced(false);
		if(props.onObtain) props.onObtain();
		if(!firstObtain) setFirstObtain(true);
		addToInventory && addToInventory(props.itemId);
	};

	return(
		<group 
			ref={ref}
			{...props}
		>
			<PointerHandler onInteract={interact} interactive={inspectCheck}>
				<Select enabled={props.obtainable && placed && inspectCheck}>
					{
						placed ? 
							Children.toArray(props.children)[0]
							: indicator ?
								Children.toArray(props.children)[1]
								:  <></>
					}
				</Select>
				{inspectCheck && !placed &&
				<mesh position={[0,0,0]} scale={props.hitboxScale ? props.hitboxScale : [1,1,1]} ref={hitbox} renderOrder={1}>
					<meshBasicMaterial opacity={props.hitboxDebug ? 0.5 : 0} color={'purple'} transparent/>
					<boxBufferGeometry/>
				</mesh>}{/* for placing */}
				{Children.toArray(props.children)[2]}{/* for pickup */}
			</PointerHandler>
		</group>
	);
};

// types

type InventoryObjectProps = {
	children: ReactNode
    itemId: number
	hitboxScale?: Vector3Tuple
	hitboxDebug?: boolean
	replace?: boolean // prevent placing after picking up
	reobtain?: boolean // prevent picking up after placing
	obtainable?: boolean // prevent picking up
	placeable?: boolean // prevent placing
	overwriteBlock?: boolean; // if true the it blocks the inspection mode check
	initialyPlaced: boolean
	onPlace?: () => void
	onObtain?: () => void
};