import styled from 'styled-components';
import { keyframes } from 'styled-components';
import { useGlobalContext } from '../../context/contexts/global/GlobalContext';
import { useTextContext } from '../../context/contexts/text/TextContext';
import Theme from '../../style/theme';
import { Backdrop } from './components/Container';
import Firework from './components/Fireworks';
import HeadingBlock from '../interface/components/HeadingBlock';
import Content from '../interface/components/containers/Content';
import { BaseButton, Button } from '../Buttons/Buttons';
import React, { useEffect, useMemo, useState } from 'react';

import left from '../../assets/images/left-arrow.png';
import right from '../../assets/images/right-arrow.png';
import { usePuzzleContext } from '../../context/contexts/puzzle/PuzzleContext';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

const EndScreen = () => {

	const {t} = useTextContext();
	const { setGameState, activeScene} = useGlobalContext();
	const {activePuzzle} = usePuzzleContext();

	const [step, setStep] = useState<number>(0);

	const content = useMemo(() => {
		if(activeScene !== 1) return content1;
		else return content2;
	},[activeScene]);

	const currentPuzzle = useMemo<number>(() => {
		if(activePuzzle === undefined) return 0;
		else return activePuzzle-1;
	},[activePuzzle]);

	const moveStep = (increment: boolean) => {
		if(increment){
			setStep(s => {
				if(content[currentPuzzle][s + 1] === undefined) return s;
				return s + 1;
			});
		} else {
			setStep(s => {
				if(content[currentPuzzle][s - 1] === undefined && s - 1 < 0) return s;
				return s - 1;
			});
		}
	};

	return (
		<Backdrop>
			<Container>
				<div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
					<h1>{t('end.title')}</h1>
					<p>{t('end.text')}</p>
					{/* <p>In het kader hiernaast kan je per onderwerp meer informatie en linkjes naar interessante websites vinden.</p> */}
					<Button style={{margin: 'auto auto 0 0'}}onClick={() => setGameState && setGameState(3)}>terug naar hoofdmenu</Button>
				</div>
				<div style={{backgroundColor: 'rgba(0,0,0,0.75)', display: 'flex', flexDirection: 'column'}}>
					<HeadingBlock>{content[currentPuzzle][step].title ?? 'title'}</HeadingBlock>
					<Content style={{display: 'flex', flexDirection: 'column'}}>
						<StyledReactMarkdown linkTarget="_blank">{`${content[currentPuzzle][step].description ?? 'description'}`}</StyledReactMarkdown>
						<Navigation>
							<Button iconURL={left} onClick={() => moveStep(false)}/>
							<Counter> {content[currentPuzzle].map((value, index) => <Count key={`count-${index}`} selected={step === index}/>)} </Counter>
							<Button iconURL={right} onClick={() => moveStep(true)}/>
						</Navigation>
					</Content>
				</div>
				<Firework/>
			</Container>
		</Backdrop>
	);
};

// styled components

const StyledReactMarkdown = styled(ReactMarkdown)`
	& > p > a {
		color: ${p => p.theme.colors.primary}!important;
	}
`;

const Counter = styled.div`
	display: flex;
	gap: 7px;
	height: 100%;
	justify-content: center;
	align-items: center;
`;

const Count = styled.div<{selected: boolean}>`
	height: 12px;
	width: 12px;
	background-color: ${p => p.selected ? 'white' : 'grey'};
	border-radius: 100%;
`;

const Navigation = styled.div`
	display: flex;
	justify-content: space-between;

	margin-top: auto;
`;

const fadeIn = keyframes`

	from {
		opacity: 0;
		& > * {
			opacity: 0;
		}
	}

	to {
		opacity: 1;
		& > * {
			opacity: 0;
		}
	}

`;

const Container = styled.div`
	height: 100%;
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 40%;
	gap: 100px;
	background: linear-gradient(90deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.6) 60%, rgba(0,0,0,0) 100%);
	animation: ${fadeIn} 1s forwards;
	text-align: left;

	
	overflow-y: auto;

	// Extra small screens
	@media (min-width: ${Theme.responsive.media.xs}){
		padding: ${Theme.responsive.whitespace.xs}px;
	}

	// Small screens (tablets, big phones, small monitors).
	@media (min-width: ${Theme.responsive.media.sm}){
		padding: ${Theme.responsive.whitespace.sm}px;
	}

	// Large screens
	@media (min-width: ${Theme.responsive.media.lg}){
		padding: ${Theme.responsive.whitespace.lg}px;
	}

	// Extra large screens
	@media (min-width: ${Theme.responsive.media.xl}){
		padding: ${Theme.responsive.whitespace.xl}px;

	}

	& > h1 {
		margin: 0;
	}
	color: ${Theme.colors.neutralLightest};

`;

// data

const sharedContent : ContentData = [
	//energieverbruik
	[
		{
			title: 'Verbruik wasmachine',
			description: 'Witgoed verbruikt veel energie. Gelukkig worden de meeste apparaten steeds energie-efficiënter. Daardoor kan het voordelig zijn om eens in de 7 jaar je witgoedapparatuur te vervangen. Kijk dan goed naar de energielabels als je een nieuwe gaat kopen. Er is echt een groot verschil in zuinigheid! Het energielabel wordt gebaseerd op het gebruik in eco-modus. Het is dus belangrijk om het apparaat dan ook in die modus te gebruiken. Benieuwd welke bespaarmaatregelen goed bij jouw woning passen? Kijk [hier](https://www.milieucentraal.nl/tests-en-tools/verbeterjehuis/).',
		},
		{
			title: 'Verbruik TV',
			description: 'Stand-by apparaten gebruiken weinig energie in vergelijking met bijvoorbeeld witgoed. Maar toch verbruiken ze energie ook als het helemaal niet nodig is! Om te voorkomen dat ze onnodig energie gebruiken moet je de apparaten uit zetten in plaats van op stand-by. Dat kun je doen door het plaatsen ven een ‘killswitch’. Voor meer informatie zie [link](https://www.milieucentraal.nl/energie-besparen/apparaten-in-huis/voorkom-sluipgebruik/).',
		},
		{
			title: 'Verbruik fornuis',
			description: 'Het gasfornuis is slechts verantwoordelijk voor een klein deel van het totale gasverbruik. Toch is het een goede stap om over te gaan van koken op gas naar koken op inductie. Voor deze overstap hoef je niet altijd een volledig nieuwe pannenset aan te schaffen. Je kunt checken op je huidige pannen geschikt zijn door er een magneetje onder te houden. Als die blijft zitten is de pan geschikt voor inductie! Meer informatie over inductie koken: [link](https://www.milieucentraal.nl/energie-besparen/apparaten-in-huis/inductie-kookplaat/).',
		},
		{
			title: 'Verbruik verwarming',
			description: 'De verwarming is de grootste gasverbruiker in het huis. Door de verwarming een graad lager te zetten kun je al aanzienlijk besparen! Een slimme thermostaat kan je met de verschillende programma’s ook helpen om minder te gebruiken. Hulp om je CV-ketel zuiniger in te stellen vind je op [zetmop60.nl](https://www.zetmop60.nl) ',
			link: ''
		}
	],
	//energie opwekken
	[
		{
			title: 'Leveranciers',
			description: 'Er zijn veel verschillende energieleveranciers op de Nederlandse markt. Wil jij beter inzicht krijgen over hoe duurzaam je huidige energieleverancier is en welke leverancier misschien een groenere optie is? Vergelijk alle leveranciers in het overzicht op [link](https://wisenederland.nl/groene-energievergelijker/).'  
		},
		{
			title: 'Zonnepanelen',
			description: 'Het plaatsten van zonnepanelen kan alleen de eigenaar van de woning doen. Voordat je vol enthousiasme zonnepanelen wil gaan plaatsen, is het belangrijk om te controleren of de dakconstructie het wel kan dragen. Het is niet interessant om meer energie op te wekken dan je nodig hebt. Wil je weten hoeveel je zonnepanelen jou kunnen opleveren en op welk termijn? Check dan [deze link](https://www.milieucentraal.nl/energie-besparen/zonnepanelen/kosten-en-opbrengst-zonnepanelen/).',
		},
		{
			title: 'Windmolens',
			description: 'In windmolenparken wordt uit de onuitputtelijke bron wind energie opgewekt. Windturbines wekken stroom op zonder de lucht te vervuilen, zonder het klimaat te belasten en zonder grondstoffen uit te putten. Uitgebreide informatie over windenergie kun je lezen op [link](https://www.milieucentraal.nl/klimaat-en-aarde/energiebronnen/windenergie/).',
		},
		{
			title: 'Warmtenet',
			description: 'Met een warmtenet kunnen meerdere huizen in een wijk verwarmd worden. En het doel is om in 2030 een kwart van alle wijken in Nederland aan te sluiten op een warmtenetwerk. Lijkt jou dat ook interessant? Lees dan alles over wat een warmtenet is en kan op [link](https://www.milieucentraal.nl/energie-besparen/aardgasvrij-wonen/warmtenet-zonder-aardgas/).'
		},
		{
			title: 'Biogas',
			description: 'Voor uitgebreide informatie over biogas en hoe jij daar op kunt aansluiten kun je kijken op: [link](https://www.milieucentraal.nl/klimaat-en-aarde/energiebronnen/biomassa/#biogas).',
		}
	],
	//energie transport
	[
		{
			title: 'Aansluiting zonnepanelen',
			description: 'Om geplaatste zonnepanelen goed aan te laten sluiten op je energienetwerk is het noodzakelijk om de meterkast gereed te maken. Het plaatsen van zonnepanelen kan meestal in een dag gedaan worden. Het verplaatsen van leidingen kost vaak meer tijd. Ben je eigenaar van de woning? Dan moet je voor het plaatsen van zonnepanelen en het verleggen van leidingen zelf een bedrijf inschakelen. Een huurder heeft niet de bevoegdheid om dit te laten doen en zal in overleg met de verhuurder deze stappen kunnen onderzoeken. Wil je weten welke stappen jij kunt zetten richten een aardgasvrije woning? Kijk dan hier [link](https://www.milieucentraal.nl/energie-besparen/aardgasvrij-wonen/stappenplan-aardgasvrij-wonen/).',
		},

	],
];

const content1 = [
	...sharedContent,
	//energieplan eigenaar
	[
		{
			title: 'Energieplan',
			description: 'Om je woning te verduurzamen kun je deze stappen in volgorde nemen:\n1. Gedrag\n2. Kleine maatregelen\n3. Motiveren verhuurder\n4. Verwarming\n5. Koken\n6. Aardgasvrij \n\nZie wat de verschillende stappen inhouden door op het pijltje naar rechts te klikken.'
		},
		{
			title: 'Gedrag',
			description: 'Met het aanpassen van je gedrag kan je heel veel energie besparen. Het kost niks, en je verdient het meteen terug! Er is veel wat je kunt doen. Bijvoorbeeld aan korter douchen, de verwarming lager zetten, lichten uit doen zodra je de kamer verlaat en het slaapverbruik van apparaten tegengaan door ze na gebruik uit te zetten. Meer voorbeelden vind je op [link](https://www.milieucentraal.nl/over-milieu-centraal/ontdek-wat-jij-kan-doen/).',
		},
		{
			title: 'Kleine maatregelen',
			description: 'Je woning verduurzamen hoeft helemaal geen grote investering te zijn. Met tochtstrips kun je kieren dichten om het huis beter te isoleren, met radiatorfolie en -boosters kun je de efficiëntie van je verwarming verhogen en met raamfolie kun je zelfs enkel glas beter laten isoleren! Voor meer bespaartips zie [link](https://www.milieucentraal.nl/energie-besparen/snelle-bespaartips/).',
		},
		{
			title: 'Grote maatregelen',
			description: 'Om de verduurzaming van je woning grondig aan te pakken kun je grotere maatregelen treffen. Je kunt het beste beginnen de ramen te vervangen met HR++ glas voor betere isolatie. Het isoleren van een spouwmuren is vaak ook erg rendabel. Het isoleren van de vloer en het dak zijn ook manieren om je huis te verduurzamen. Vergeet niet dat het ook belangrijk is om te denken aan de ventilatie van je woning. Informatie over wat je zelf kunt doen vind je hier [link](https://www.milieucentraal.nl/tests-en-tools/verduurzamen-doe-het-zelf/).',
		},
		{
			title: 'Verwarming',
			description: 'Als je al vaak de verwarming lager zet en toch nog meer wilt verduurzamen kun je de verwarming door een monteur waterzijdig laten inregelen. Dit zorgt ervoor dat het warme water beter verdeeld wordt over de verschillende radiatoren en de warmte dus beter verspreid wordt in de woning.\nEen verdere stap kan zijn om over te stappen op een hybride of elektrische warmtepomp. Alles wat je wil weten over de warmtepomp vind je op [link](https://www.milieucentraal.nl/energie-besparen/duurzaam-verwarmen-en-koelen/warmtepomp-duurzaam-elektrisch-verwarmen/)',
		},
		{
			title: 'Koken',
			description: 'Om duurzaam te koken kun je overstappen op inductie. Voor deze overstap zijn twee dingen belangrijk. Het eerste is dat er een 3 fasen-aansluiting in de meterkast aanwezig moet zijn voor een goede aansluiten. Als tweede moet je zorgen dat je pannen hebt die op een inductieplaat kunnen. Je kunt simpel checken of de pannen die je nu hebt daarvoor geschikt zijn door een magneetje onder de pan te houden en te kijken of die aan de onderkant vast blijft zitten. Meer informatie over inductie koken: [link](https://www.milieucentraal.nl/energie-besparen/apparaten-in-huis/inductie-kookplaat/).',
		},
		{
			title: 'Aardgasvrij',
			description: 'De woning aardgasvrij maken kan met een warmtepomp (hybride of elektrisch). Dit is een laatste stap die je kunt zetten in het verduurzamen van je huis. Wil je weten wat de verschillende stappen zijn naar het aardgasvrij maken van de woning? Kijk dan op [link](https://www.milieucentraal.nl/energie-besparen/aardgasvrij-wonen/stappenplan-aardgasvrij-wonen/).',
		}
	]
];

const content2 = [
	...sharedContent,
	//energieplan huurder
	[
		{
			title: 'Energieplan',
			description: 'Om je woning te verduurzamen kun je deze stappen in volgorde nemen: \n1. Gedrag\n2. Kleine maatregelen\n3. Motiveren verhuurder\n4. Collectieve aanpak\n\nZie wat de verschillende stappen inhouden door op het pijltje naar rechts te klikken.'
		},
		{
			title: 'Gedrag',
			description: 'Met het aanpassen van je gedrag kan je heel veel energie besparen. Het kost niks, en je verdient het meteen terug! Er is veel wat je kunt doen. Bijvoorbeeld aan korter douchen, de verwarming lager zetten, lichten uit doen zodra je de kamer verlaat en het slaapverbruik van apparaten tegengaan door ze na gebruik uit te zetten. Meer voorbeelden vind je op [link](https://www.milieucentraal.nl/over-milieu-centraal/ontdek-wat-jij-kan-doen/).',
		},
		{
			title: 'Kleine maatregelen',
			description: 'Je woning verduurzamen hoeft helemaal geen grote investering te zijn. Met tochtstrips kun je kieren dichten om het huis beter te isoleren, met radiatorfolie en -boosters kun je de efficiëntie van je verwarming verhogen en met raamfolie kun je zelfs enkel glas beter laten isoleren! Een energiecoach kan je daarbij helpen. Voor meer bespaartips zie [link](https://www.milieucentraal.nl/energie-besparen/snelle-bespaartips/).',
		},
		{
			title: 'Motiveren verhuurder',
			description: 'Als huurder kun je helaas geen grote maatregelen treffen in je woning. Daarom is het goed om in gesprek te gaan met je verhuurder. Vanaf 2029 moeten alle huurwoningen een energielabel D of hoger hebben. Voor de verhuurder is het dus ook interessant om te investeren in verduurzaming. Tips om het gesprek met de verhuurder aan te gaan vind je op [link](https://www.milieucentraal.nl/energie-besparen/energie-besparen-in-een-huurwoning/).',
		},
		{
			title: 'Collectieve aanpak',
			description: 'Met het collectief inkopen van bijvoorbeeld zonne-energie kun je samen met je buurtbewoners op een simpele manier verduurzamen. Je hoeft geen grote veranderingen in de woning aan te brengen om toch duurzame energie te kunnen gebruiken. Wordt de energie door de verhuurder ingekocht? Dan kun je met de verhuurder in gesprek gaan om op duurzamere energie over te gaan.'
		}
	]
];

// types

type ContentData = ContentLevel[];
type ContentLevel = ContentSlice[]
type ContentSlice = {
	title: string,
	description: string,
	link?: string
}


export default EndScreen;