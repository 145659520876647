import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import soundlist from '../../../assets/Sounds';
import { useAudioContext } from '../audio/AudioContext';
import { useGlobalContext } from '../global/GlobalContext';
import { data, PuzzleData } from './PuzzleData';
import { useInventoryContext } from '../inventory/InventoryContext';
import { useThree } from '@react-three/fiber';
import { Vector3 } from 'three';

export const PuzzleContext = createContext<Partial<PuzzleContextProps>>({});

export const PuzzleContextProvider = (props: {children: ReactNode}) => {

	const {setToggleHintTimer, setTutorialStep, activeScene, setActiveDialogueMessage, setActiveRoom, gameState, setCameraAction} = useGlobalContext();
	const {resetInventory, addToInventory} = useInventoryContext();

	//#region use states
	const [activePuzzle, setActivePuzzle] = useState<number>(4);
	const [puzzleComplete, setPuzzleComplete] = useState<boolean>(false);
	const {playSound} = useAudioContext();

	const [puzzleData, setPuzzleData] = useState<PuzzleData>(data);
	//#endregion

	//#region functions

	useEffect(() => {
		if(gameState !== 0 && puzzleComplete){
			setPuzzleComplete(false); // reset when going back to menu
		}
	}, [gameState]);

	// reset to after the tutorial
	const resetPuzzleData = () => {
		setPuzzleData(data);
		setTutorialStep && setTutorialStep(10);
		changePuzzleData (0,2,1);
		changePuzzleData (0,4,1);
		changePuzzleData (0,3,1);
		changePuzzleData (0,0,1);
	};

	const changePuzzleData = (puzzleId: number, checkId: number, checkValue: number) => {
		setPuzzleData && setPuzzleData(currentPuzzleData => {
			return currentPuzzleData.map((puzzleDataBlock, puzzleDataBlockIndex) => {
				if(puzzleId === puzzleDataBlockIndex){
					return {
						hintPackageId : puzzleDataBlock.hintPackageId,
						checks : puzzleDataBlock.checks.map((check, checkIndex) => {
							if(checkId === checkIndex){
								return checkValue;
							}
							else{
								return check;
							}
						})
					};
				}
				else{
					return puzzleDataBlock;
				}
			});
		});
	};
	//#endregion

	useEffect(() => {
		if(puzzleComplete) playSound && playSound(soundlist['puzzleCompleted']);
	}, [puzzleComplete]);

	useEffect(() => {
		if(gameState !== 0) resetPuzzleData();
	}, [gameState]);

	useEffect(() => {
		if(gameState !== 0) return;
		setToggleHintTimer && setToggleHintTimer(true);
		resetInventory && resetInventory();
		switch(activePuzzle){
		case 0:
			setCameraAction && setCameraAction({type: 'reset', parameters: {position: new Vector3(-1.5,1.7,-0.25), facing: 'x-'}});
			setActiveRoom && setActiveRoom('hal');
			setTutorialStep && setTutorialStep(1);
			break;
		case 1:
			setCameraAction && setCameraAction({type: 'reset', parameters: {position: new Vector3(-1.5,1.7,-0.25), facing: 'x-'}});
			addToInventory && addToInventory(0);
			setActiveRoom && setActiveRoom('hal');
			setTimeout(() => setActiveDialogueMessage && setActiveDialogueMessage('tutorial.klaar.speler-1'), 1000);
			changePuzzleData && changePuzzleData(1,0,1);
			break;
		case 2:
			// set starting pos
			// add item
			setCameraAction && setCameraAction({type: 'reset', parameters: {position: new Vector3(-1.5,1.7,-0.25), facing: 'x-'}});
			setActiveRoom && setActiveRoom('zolder');
			setTimeout(() => setActiveDialogueMessage && setActiveDialogueMessage(`puzzel2.binnenkomst.${activeScene === 1 ? 'modi' : 'sandra'}-1`), 1000);
			break;
		case 3:
			setCameraAction && setCameraAction({type: 'reset', parameters: {position: new Vector3(-1.7,1.7,-1.5), facing: 'x-'}});
			setActiveRoom && setActiveRoom('kelder');
			setTimeout(() => setActiveDialogueMessage && setActiveDialogueMessage(`puzzel3.binnenkomst.${activeScene === 1 ? 'modi' : 'sandra'}-1`), 1000);
			break;
		case 4:
			setCameraAction && setCameraAction({type: 'reset', parameters: {position: new Vector3(-.5,1.7,0), facing: 'x-'}});
			setActiveRoom && setActiveRoom('art');
			break;
		}
	},[activePuzzle, gameState]);
	
	const activeHintPackageId = useMemo<number>(() => 
		puzzleData[activePuzzle].hintPackageId
	,[activePuzzle]);

	const passedValues = {
		activePuzzle,
		activeHintPackageId,
		puzzleData,
		puzzleComplete
	};

	const passedFunctions = {
		setPuzzleData,
		setActivePuzzle,
		setPuzzleComplete,
		changePuzzleData
	};

	return(
		<PuzzleContext.Provider value={{...passedValues, ...passedFunctions}}>
			{props.children}
		</PuzzleContext.Provider>  
	);
};

export const usePuzzleContext = () => useContext(PuzzleContext);

// types

type PuzzleContextProps = {
    
    activePuzzle: number,
	setActivePuzzle: (val: number) => void,
	activeHintPackageId: number,

    puzzleData: PuzzleData,
	setPuzzleData: Dispatch<SetStateAction<PuzzleData>>

	changePuzzleData: (puzzleId: number, checkId: number, checkValue: number) => void
	puzzleComplete: boolean
	setPuzzleComplete: Dispatch<SetStateAction<boolean>>

}
