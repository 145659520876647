import { useMemo } from 'react';
import styled from 'styled-components';
import backdrop from '../../../assets/images/backdrop.png';
import { useDataContext } from '../../../context/contexts/data/DataContext';

export const Backdrop = (props: JSX.IntrinsicElements['div']) => {

	const {getFileURL} = useDataContext();
	
	const BackDropImg = useMemo(() => {
		return getFileURL && getFileURL('79e12ef5-b326-49e0-9321-44abb18d3d80');
	},[]);

	return (
		<_Backdrop {...props} imageURL={BackDropImg}>
			{props.children}
		</_Backdrop> 
	);
};


const _Backdrop  = styled.div<{imageURL: string}>`

	height: 100%;
	width: 100%;

	inset: 0;

	background: green;

    background-image: url(${backdrop});
    background-size: cover;
    background-position: center;
	
`;