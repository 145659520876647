import { GroupProps } from '@react-three/fiber';
import { Select } from '@react-three/postprocessing';
import { createContext, forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { Group } from 'three';
import { useGlobalContext } from '../../../../context/contexts/global/GlobalContext';
import { usePuzzleContext } from '../../../../context/contexts/puzzle/PuzzleContext';
import { InspectData, useCameraMovementContext } from '../../controls/Movement/CameraMovement';
import { PointerHandler } from './PointerHandler';

export const InteractiveObjectContext = createContext({objectId: ''});

const InteractiveObject = forwardRef<Group, GroupProps & InteractiveObjectProps>((props, ref) => {

	const _ref = useRef<Group>(null);

	const {cameraType, tutorialStep} = useGlobalContext();
	const {inspect} = useCameraMovementContext();
	const {puzzleData} = usePuzzleContext();
	const [objectAvailable, setObjectAvailable] = useState<boolean>(false);

	const check = useMemo<boolean>(() => {
		if(!puzzleData) return false;

		const c = !props.lockedUntil ? true : puzzleData[props.lockedUntil.puzzleId].checks[props.lockedUntil.checkId] === props.lockedUntil.checkValue;
		return c ? c : false; 
	},[puzzleData]);

	useEffect(() => {
		if(!check || cameraType === 'inspection'){
			setObjectAvailable(false);
		} else {
			setObjectAvailable(true);
		}
	},[check, cameraType]);

	const clickHandler = () => {
		if(!inspect  || !objectAvailable) return;

		if(tutorialStep && tutorialStep >= 4){//in step 4 the player learns inspecting
			
			const inspectData = {...props.inspectData};
			if(inspectData.localPositions && _ref.current){
				inspectData.cameraPosition = _ref.current.localToWorld(inspectData.cameraPosition);
				inspectData.newTarget = _ref.current.localToWorld(inspectData.newTarget);
			}
			inspect(inspectData);

		}
	};

	return(
		<group ref={ref} {...props}>
			<group ref={_ref}>
				<InteractiveObjectContext.Provider value={{objectId: props.inspectData.objectId}}>
					<Select enabled={objectAvailable}>
						<PointerHandler onInteract={() => clickHandler()}>
							{ // eslint-disable-next-line react/prop-types
								props?.children
							}
						</PointerHandler>
					</Select>
				</InteractiveObjectContext.Provider>
			</group>
		</group>
	);
});

// types

type InteractiveObjectProps = {
	inspectData: InspectData,
	lockedUntil?: {puzzleId: number, checkId: number, checkValue: number};
}

export default InteractiveObject;