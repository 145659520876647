import styled from 'styled-components';
import { useGlobalContext } from '../../../context/contexts/global/GlobalContext';
import Theme from '../../../style/theme';
import { Button } from '../../Buttons/Buttons';
import backIcon from '../../../assets/icons/back_icon.png';
import { useDataContext } from '../../../context/contexts/data/DataContext';
import { css } from 'styled-components';
import { useMemo } from 'react';

const BackButton = () => {

	const {cameraType, setCameraAction} = useGlobalContext();
	const {getFileURL} = useDataContext();

	const handleOnClick = () => {
		if(cameraType !== 'inspection') return;
		setCameraAction && setCameraAction({type: 'back'});
	};
	const BackButtonIcon = useMemo(() => {
		return getFileURL && getFileURL('b6e3924d-a27d-46ce-88b3-7445c3117168');
	},[]);

	return( 
		<> 
			<Back onClick={() => handleOnClick()} iconURL={BackButtonIcon} active={cameraType === 'inspection'} disabled={cameraType !== 'inspection'}/>
		</>
	);
};

//styled components
const Back = styled(Button)`
	position: absolute;
	right: 0;
	top:0;
	height: 100px;
	width: 100px;
	// Extra small screens
	@media (min-width: ${Theme.responsive.media.xs}){
		margin: ${Theme.responsive.whitespace.xs  / 2}px;
	}

	// Small screens (tablets, big phones, small monitors).
	@media (min-width: ${Theme.responsive.media.sm}){
		margin: ${Theme.responsive.whitespace.sm / 2}px;

	}

	// Large screens
	@media (min-width: ${Theme.responsive.media.lg}){
		margin: ${Theme.responsive.whitespace.lg / 2}px;

	}

	// Extra large screens
	@media (min-width: ${Theme.responsive.media.xl}){
		margin: ${Theme.responsive.whitespace.xl / 3}px;
	}

	transition: all 1s;
	
	${p => p.active ? css`
		transform: translateX(0);
	` : css `
		pointer-events: none;
		transform: translateX(50vw);
	
	`
}


`;
export default BackButton;