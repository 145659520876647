import { Directus, ID} from '@directus/sdk';
import { RateLimiter } from 'limiter';
import { Directer } from './directer/directer';

export const use_cms = false; //change to false for local data

export const directus_url = 'https://3jyslj26.directus.app/';
export const directus_auth_token = 'JeHfCA_SfTOTZvPYg4aurYbKiLDJwVL9';

type DirectusType = 
| Directus<DirectusProps>
| Directer<DirectusProps>;

export let directus: DirectusType; 

if(use_cms){
	directus = new Directus<DirectusProps>(directus_url, {auth: {staticToken: directus_auth_token}});
} else {
	directus = new Directer<DirectusProps>();
}

export const limiter = new RateLimiter({ tokensPerInterval: 10, interval: 1000});

export type DirectusProps = {
    text_translations: TextTranslation,
	directus_files: File,
	HintPackages_translations: HintPackageTranslation,
	HintPackages: HintPackage,
	ChatMessage: ChatMessage,
	ChatMessage_translations: ChatMessageTranslation
	ChatString: ChatString
	ChatString_ChatMessage: ChatString_ChatMessage
	NPC: NPC,
	NPC_translations: NPCTranslation
	dialogue_messages: DialogueMessage
	dialogue_messages_translations: DialogueMessageTranslation
}

export type DialogueMessage = {
	key: string; // for identification in Directus and indexing
	sender: number; // 1 === npc; 2 === player
	text: ID; // translation ID
	start_conversation: boolean;
	follow_up_message: string; // next chat message (if there is no next_message, this is an indication that the chat may be closed by the player).
	follow_up_delay: number; // delay in ms before the next chat message is shown
	mood: number; // if empty, mood will be changed to default
	response_1: string; // reference to other dialogue_messages
	response_2: string; 
}

export type DialogueMessageTranslation = {
	id: ID;
	dialogue_messages_key: string;
	languages_code: string;
	text: string;
	short_text: string; // for use in an option
}

export type TextTranslation = {
	id: ID,
	languages_code: string,
	text: string,
	markdown: string,
	text_key: string,
}

export type HintPackageTranslation = {
	id: ID,
	hint_packages_id: ID,
	hint_1: string,
	hint_2: string,
	hint_3: string,
	languages_code: string,
}

export type HintPackage = {
	id: ID,
	translations: ID,
}

export type File = {
	id: ID,
	modified_on: string,
	filename_disk: string,
	filename_download: string,
}

export type ChatMessage = {
	id: ID,
	translations: ID,
	order: number 
	sender: 'player' | 'npc'
}

export type ChatMessageTranslation = {
	id: ID,
	text: string,
	languages_code: string,
}

export type ChatString = {
	id: ID,
	npc : NPC
	messages : ID[]
	chat_messages: ID[] // relatie tot de junction collection
}

export type ChatString_ChatMessage = {
	id: ID,
	ChatString_id: ID,
	ChatMessage_id: ID,
	order: number,
}

export type NPC = {
	id: ID,
	name: string
	avatar: ID 
	translations: ID 
}

export type NPCTranslation = {
	id: ID,
	languages_code: string,
	description: string
}