import styled from 'styled-components';
import { DialogueProps } from './Dialogue';
import { useEffect, useState, useRef } from 'react';
import { css } from 'styled-components';
import Theme from '../../../../style/theme';
import { useDataContext } from '../../../../context/contexts/data/DataContext';
import { keyframes } from 'styled-components';


export const DialogueResponses = (props: DialogueProps & DialogueResponsesProps) => {

	const {data} = useDataContext();
	const [selection, setSelection] = useState<number>(1);
	const [visible, setVisible] = useState<boolean>(false);

	useEffect(() => {
		props.setSelection(selection);
	},[selection]);

	const visibleTimeout = useRef<NodeJS.Timeout>();
	useEffect(() => {
		setSelection(1);

		if(!props.message) return;
		
		setVisible(false);

		if(visibleTimeout.current)
			clearTimeout(visibleTimeout.current);
		visibleTimeout.current = setTimeout(() => {
			setVisible(true);
		},1000);

		return () => {
			if(visibleTimeout.current)
				clearTimeout(visibleTimeout.current);
		};

	},[props.message]);


	return (
		<>
			{ props.conversation &&
				<ResponseContainer visible={visible}>
					{ props.message && 
					<>
						{ props.message.object.response_1 &&
							<Response selected={selection === 1}  onClick={()=> setSelection(1)}>
								<div>
									{/* afknippen als er geen response2 is en hij te lang is */}
									{data && data.dialogue && data.dialogue[props.message.object.response_1].translation.short_text}
								</div>
							</Response>
						}
						{ props.message.object.response_2 &&
							<Response selected={selection === 2} onClick={()=> setSelection(2)}>
								<div>
									{data && data.dialogue && data.dialogue[props.message.object.response_2].translation.short_text}
								</div>
							</Response>
						}
					</>
					}
				</ResponseContainer>
			}
		</>
	);
};

// keyframes

const delay = keyframes`
	0%{
		opacity: 0;
		pointer-events: none;
	}
	99%{
		pointer-events: none;
	}
	100%{
		opacity: 1;
		pointer-events: all;
	}
`;

// styled components

const ResponseContainer = styled.div`
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-right: 10px;
	opacity: 0;
	${p => p.visible && css`animation: ${delay} 1s forwards`};
`; 

const Response = styled.div`
	cursor: pointer;
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	/* text-align: center; */
	background-color: white;
	border-radius: 5px;
	padding: 5px;

	${p => p.selected ? css`
		/* color: white; */
		outline: solid 2px ${Theme.colors.primary}!important;
		opacity: 1!important;
		`: css`
		/* color: white; */
		opacity: .5;
		`
}

	&:hover {
		outline: solid 2px ${Theme.colors.primaryLighter} ;
		opacity: .8;
	}


	& > div {
		position: absolute;
		inset: 0;
		overflow: hidden;
		text-overflow: ellipsis;
		padding: 5px;
	}
`;

// types

type DialogueResponsesProps = {
	conversation: boolean;
	setSelection: (number: number) => void;
	messageVisability: boolean;
} 