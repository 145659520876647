import * as THREE from 'three';

import { Canvas } from '@react-three/fiber';
import { ReactNode } from 'react';
import styled from 'styled-components';
import Controls from './controls/Controls';
import { EffectComposer, Outline, Selection } from '@react-three/postprocessing';
import CameraMovementContext from './controls/Movement/CameraMovement';
import { CycleRaycast, Environment, useContextBridge } from '@react-three/drei';
import { GlobalContext, useGlobalContext } from '../../context/contexts/global/GlobalContext';
import ThreeContext from '../../context/contexts/three/ThreeContext';
import { PuzzleContext } from '../../context/contexts/puzzle/PuzzleContext';
import { InventoryContext } from '../../context/contexts/inventory/InventoryContext';
import { DataContext } from '../../context/contexts/data/DataContext';
import hdr from '../../assets/images/main.hdr';
import { AudioContext } from '../../context/contexts/audio/AudioContext';
 
const ThreeCanvas = (props: {children: ReactNode}) => {

	const ContextBridge = useContextBridge(GlobalContext, PuzzleContext, InventoryContext, DataContext, AudioContext);

	const {interactablesVisible, gameState} = useGlobalContext();
	//const {}; 

	return (
		<CanvasContainer >
			<Canvas 
				camera={camera}
				shadows
				dpr={0.8}
				// frameloop={'demand'}
				// performance={{ min: 0.8, max: 1}}
			>
				<ContextBridge>
					<ThreeContext>
						<CameraMovementContext>
							{/* <ambientLight position={[0,0,0]} intensity={1}/>
							<directionalLight intensity={1} position={[0,0,0]}/> */}
							<Environment files={hdr}/>
							{/* <AdaptiveDpr/> */}
							<Controls/>
							<Selection>
								{gameState === 0 && props.children}
								<EffectComposer autoClear={false}>
									<Outline 
										visibleEdgeColor={0xFFFFFF}
										hiddenEdgeColor={0xFFFFFF} 
										edgeStrength={interactablesVisible ? 20 : 0} 
										blur
										//xRay={false}
									/>
								</EffectComposer>
							</Selection>
						</CameraMovementContext>
					</ThreeContext>
				</ContextBridge>
			</Canvas>
		</CanvasContainer>
	);
};

// styled components

const CanvasContainer = styled.div`

	z-index: 2;
    height: 100%;
    width: 100%;

	/* cursor: grab; */

	&:active {
		/* cursor: grabbing; */
	}

`;

const camera = {
	position: new THREE.Vector3(-2,1.7,-1),
	fov: 80,
	near: 0.005,
};



export default ThreeCanvas;