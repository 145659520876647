import { ReactNode } from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';
import { keyframes } from 'styled-components';
import Theme from '../../style/theme';

const BaseButton = styled.button`
	outline: none;
	border: none;
	cursor: pointer;
	font-size: inherit;
	font-family: inherit;
	font-weight: bold;
`;

const Button = (props: JSX.IntrinsicElements['button'] & ButtonProps) => {
	return (
		<_Button {...props} timeData={props.timeData} iconURL={props.iconURL}>
			{props.iconURL &&
				<Icon iconURL={props.iconURL}/>
			}
			<span> {props.children} </span>
		</_Button>
	);
};

// animations

const timer = keyframes`
	from {
		background: conic-gradient(transparent 0deg, rgba(0,0,0,.7) 0deg);
		opacity: 1;
	}
	to {
		background: conic-gradient(transparent 360deg, rgba(0,0,0,.7) 0deg);
		opacity: 0;
	}
`;


// styled components

const _Button = styled(BaseButton)<ButtonProps>`
	color: ${Theme.colors.neutralLightest};
	background: linear-gradient(103deg, rgba(228,97,15,1) 0%, rgba(228,97,15,1) 30%, rgba(255,255,255,1) 60%, rgba(228,97,15,1) 90%, rgba(228,97,15,1) 100%);
	border-radius: ${Theme.responsive.borderRadius.sm};
	padding: .4rem 2rem;
	position: relative;

	&:before {
		content: '';
		position: absolute;
		inset: 1px;
		background: ${Theme.colors.neutralDarkest};
		border-radius: ${Theme.responsive.borderRadius.sm};
		transition: all .2s;
		opacity: 0.8;
		z-index:0;
	}

	&:after{
		content: '';
		position: absolute;
		inset:0;
		/* background-color: rgba(0,0,0,0.4); */
		border-radius: ${Theme.responsive.borderRadius.sm};
		${p => {if(!p.timeData || p.timeData.time === 0) return; 
	}}

		background: ${p => {if(!p.timeData || p.timeData.time === 0) return ''; return `conic-gradient(transparent ${(p.timeData.time / p.timeData.maxTime) * 360}deg, rgba(0,0,0,.7) 0deg)`;}};
		
		
		/* animation: ${timer} 10s; */
	}

	&:hover{
		:before {
			transform: scale(0.95);

			opacity: 0.5;
		}
	}

	& > span {
		position: relative;
		z-index: 2;
	}

	&:disabled{
		filter: grayscale(100%);
		pointer-events: none;
	}


	// Extra small screens
	@media (min-width: ${Theme.responsive.media.xs}){
		font-size: 1rem;
	}

	// Small screens (tablets, big phones, small monitors).
	@media (min-width: ${Theme.responsive.media.sm}){
		font-size: 1.2rem;
	}

	// Large screens
	@media (min-width: ${Theme.responsive.media.lg}){
		font-size: 1.4rem;

	}

	// Extra large screens
	@media (min-width: ${Theme.responsive.media.xl}){
		font-size: 2rem;
	}

	${p => p.iconURL && css`
		padding: 0;
		// Extra small screens
		@media (min-width: ${Theme.responsive.media.xs}){
			height: 60px;
			width: 60px;
		}

		// Small screens (tablets, big phones, small monitors).
		@media (min-width: ${Theme.responsive.media.sm}){
			height: 70px;
			width: 70px;
		}

		// Large screens
		@media (min-width: ${Theme.responsive.media.lg}){
			height: 80px;
			width: 80px;
		}

		// Extra large screens
		@media (min-width: ${Theme.responsive.media.xl}){
			height: 100px;
			width: 100px;
		}
	`}
`;

const Icon = styled.div<ButtonProps>`
	position: absolute;
	inset: 17px;

	background-image: url(${p => p.iconURL});
	background-size: cover;
	background-position: center;
`;

// types

export type ButtonProps = {
	iconURL?: string
	timeData?: { time: number, maxTime: number}
}

export {BaseButton, Button};