import { lazy } from 'react';
import { RoomCheck } from '../../RoomCheck';
import Thermostaat from './PuzzleElements/Thermostaat';

const roomKey = 'overloop';

const OverloopShell = lazy(() => import('./OverloopShell'));
const OverloopInterior1 = lazy(() => import('./OverloopInterior1'));
const OverloopInterior2 = lazy(() => import('./OverloopInterior2'));
const Wasmachine = lazy(() => import('./PuzzleElements/Wasmachine'));

export const Overloop = () => {

	// render
	return (
		<>
			{/* Modie's Room */}
			<RoomCheck roomKey={roomKey} scene={1}>
				<Thermostaat/>
				<OverloopInterior1/>
			</RoomCheck>
			{/* Sandra's Room */}
			<RoomCheck roomKey={roomKey} scene={2}>
				<OverloopInterior2/>
				<Wasmachine position={[-1.65,0,0.5]} scale={0.5} rotation={[0,3.2,0]}/>
			</RoomCheck>
			{/* Shell */}
			<RoomCheck roomKey={roomKey}>
				<OverloopShell/>
			</RoomCheck>
		</>
	);
};