import * as THREE from 'three';
import React, { useEffect, useRef, useState } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import InteractiveObject from '../../../../elements/inspect or interact/InteractiveObject';
import { Vector3 } from 'three';
import { usePuzzleContext } from '../../../../../../context/contexts/puzzle/PuzzleContext';
import { InventoryObject } from '../../../../elements/inventory/InventoryObject';
import { Verbruiksmeter } from '../../Hal/PuzzleElements/Verbruiksmeter';
import { VerbruiksmeterIndicator } from '../../../../elements/inventory/indicators/VerbruiksmeterIndicator';
import model from './arcadis_thermostat-transformed.glb';
import InteractiveElement from '../../../../elements/inspect or interact/InteractiveElement';

const objectId = 'thermostaat';

type GLTFResult = GLTF & {
	nodes: {
		Cube: THREE.Mesh
		Cube_1: THREE.Mesh
		thermostat_button: THREE.Mesh
		thermostat_lid: THREE.Mesh
	}
	materials: {
		txr_thermostat: THREE.MeshStandardMaterial
		Iconen: THREE.MeshStandardMaterial
	}
  }
  
type ActionName = 'anim_thermostat_button' | 'anim_thermostat_open'
type GLTFActions = Record<ActionName, THREE.AnimationAction>

const Thermostaat = (props: JSX.IntrinsicElements['group']) => {
	const group = useRef<THREE.Group>(null);
	const { nodes, materials, animations } = useGLTF(model) as GLTFResult;
	const { actions } = useAnimations(animations, group);
	const {puzzleData} = usePuzzleContext();

	const [open, setOpen] = useState<boolean>(false);

	useEffect(() => {

		if(open){
			actions['anim_thermostat_open']?.play();
			setTimeout(() => {
				if(!actions['anim_thermostat_open']) return;
				actions['anim_thermostat_open'].paused = true;
			}, 1500);
		}
	}, [open]);

	return (
		<group ref={group} {...props} dispose={null}>
			<group name="Scene">
				<InteractiveObject 
					{...props} 
					dispose={null} 

					inspectData={{				
						cameraPosition: new Vector3(2,1.8,-1),
						newTarget: new Vector3(2,1.8,-1.6),
						localPositions: true,
						layer: 0,
						objectId: objectId,
						horizontalRestrictions: [-1, 1],
						// verticalRestrictions: [0,1.5]
					}}
				>
					<group scale={1.5}  position={[2,1.8,-1.65]} name="arcadis_thermostat_base">
						<mesh name="Cube" geometry={nodes.Cube.geometry} material={materials.txr_thermostat} />
						<mesh name="Cube_1" geometry={nodes.Cube_1.geometry} material={materials.Iconen} />
						<InteractiveElement usable={!open ? 'usable' : 'notusable'} type='click' actions={actions} actionName='anim_thermostat_button'><mesh onClick={() => setOpen(true)} name="thermostat_button" geometry={nodes.thermostat_button.geometry} material={materials.txr_thermostat} position={[0.0575, 0.04, 0.007]} rotation={[-0.33, 0, 0]} scale={0.85} /></InteractiveElement>
						<mesh name="thermostat_lid" geometry={nodes.thermostat_lid.geometry} material={materials.txr_thermostat} position={[0, -0.05, 0]} />
					</group>
					<InventoryObject itemId={0}
						scale={0.6} 
						hitboxScale={[1,1,1]} 
						position={[2.3,1.8,-1.65]}
						replace 
						reobtain={puzzleData && puzzleData[1].checks[3] === 1} 
						obtainable 
						initialyPlaced={false} 
						placeable={open}
					>
						<Verbruiksmeter puzzleId='thermostaat'/>
						<VerbruiksmeterIndicator/>
					</InventoryObject>
				</InteractiveObject>
			</group>
		</group>
	);
};

//types

useGLTF.preload(model);

export default Thermostaat;
