import styled from 'styled-components';
import { keyframes } from 'styled-components';

const IsTypingAnimation = () => {

	return (
		<Typing>
			<TypingDot/>
			<TypingDot/>
			<TypingDot/>
		</Typing>
	);
};

//styled Components

const loadingFade = keyframes`
	0% {
	opacity: 0;
	}
	50% {
	opacity: 0.8;
	}
	100% {
	opacity: 0;
	}
`;

const Typing = styled.div`
	position: absolute;
	left:50%;
	top:50%;
	transform: translate(-50%, -50%);
	/* background: #e6e6e6; */
	border-radius: 20px;
	display: flex;
	padding: 10px;
	width: max-content;
`;

const TypingDot = styled.div`
	width: 8px;
	height: 8px;
	margin: 0 4px;
	background: #8d8c91;
	border-radius: 50%;
	opacity: 0;
	animation: ${loadingFade} 1s infinite;

	&:nth-child(1) {
		animation-delay: 0s;
	}  

	&:nth-child(2) {
		animation-delay: 0.2s;
	}

	&:nth-child(3) {
		animation-delay: 0.4s;
	}
`;

export default IsTypingAnimation;