import { useGLTF, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { Material, MeshBasicMaterial, MeshStandardMaterial, Vector3 } from 'three';

// texture imports 

import lowpolymap from '../../../assets/textures/low_poly_map.jpg';
import baseColor1 from '../../../assets/textures/shell_color_01.jpg';
import baseColor2 from '../../../assets/textures/shell_color_02.jpg';
import baseMR1 from '../../../assets/textures/shell_metallic_roughness_01.jpg';
import baseMR2 from '../../../assets/textures/shell_metallic_roughness_02.jpg';
import baseNormal1 from '../../../assets/textures/shell_normal_01.jpg';
import baseNormal2 from '../../../assets/textures/shell_normal_02.jpg';
import basementColor from '../../../assets/textures/basement_color.jpg';
import basementNormal from '../../../assets/textures/basement_normal.jpg';
import woodenfloorColor from '../../../assets/textures/woodenfloor_texture.jpg';
import woodenfloorNormal from '../../../assets/textures/woodenfloor_normal.jpg';
import woodenfloorMR from '../../../assets/textures/woodenfloor_metallic_roughness.jpg';
import puzzle4Color from '../../../assets/textures/puzzle_04_atlas_baseColor.png';
import puzzle4Normal from '../../../assets/textures/puzzle_04_atlas_normal.png';
import puzzle4MR from '../../../assets/textures/puzzle_04_atlas_occlusionRoughnessMetallic.png';

const Context = createContext<Partial<ThreeContextProps>>({});

const ThreeContext = (props: {children?: ReactNode}) => {
	
	const {camera} = useThree();

	//usestates

	const [controlsEnabled, setControlsEnabled] = useState<boolean>(true);
	const [targetPosition, setTargetPosition] = useState<Vector3>(new Vector3(camera.position.x + 0.01, camera.position.y, camera.position.z));
	const [verticalRestriction, setVerticalRestriction] = useState<number[]>([0, Math.PI]);
	const [horizontalRestriction, setHorizontalRestriction] = useState<number[]>([]);

	const [graphSelected, setGraphSelected] = useState<boolean>(true);
	// materials 

	// const gltfresult = useGLTF(materialObject) as GLTFResult;

	const [materials] = useState<Materials>({
		'low_poly_material': new MeshStandardMaterial(useTexture({map: lowpolymap})),
		'base_01': new MeshStandardMaterial(useTexture({
			map: baseColor1,
			roughnessMap: baseMR1,
			normalMap: baseNormal1
		})),
		'base_02': new MeshStandardMaterial(useTexture({
			map: baseColor2,
			roughnessMap: baseMR2,
			normalMap: baseNormal2
		})),
		'basement': new MeshStandardMaterial(useTexture({
			map: basementColor,
			normalMap: basementNormal
		})),
		'woodenfloor': new MeshStandardMaterial(useTexture({
			map: woodenfloorColor,
			roughnessMap: woodenfloorMR,
			normalMap: woodenfloorNormal
		})),
		'puzzle_04': new MeshStandardMaterial(useTexture({
			map: puzzle4Color,
			roughnessMap: puzzle4MR,
			normalMap: puzzle4Normal
		}))
	});

	useEffect(() => {
		if(!materials) return;

		materials.base_01.envMapIntensity = 0.6;
		materials.base_02.envMapIntensity = 0.6;
		materials.low_poly_material.envMapIntensity = 0.4;

		materials.basement.envMapIntensity = 0.2;

		if(materials.low_poly_material.map){
			materials.low_poly_material.map.flipY = true;
		}
	},[materials]);

	const passedFunctions = {
		setControlsEnabled,
		setTargetPosition,
		setHorizontalRestriction,
		setVerticalRestriction
	};

	const passedValues = {
		controlsEnabled,
		targetPosition,
		horizontalRestriction,
		verticalRestriction,
		materials
	};

	//#region render

	return (
		<Context.Provider value={{...passedValues, ...passedFunctions}}>
			{props.children}
		</Context.Provider>
	);

	//#endregion
};

const useThreeContext = () => useContext(Context);

// types

type ThreeContextProps = {

	materials: Materials,
    controlsEnabled : boolean;
    setControlsEnabled : (val: boolean) => void;

	targetPosition : Vector3;
	setTargetPosition : (val: Vector3) => void;

	//restrictions
	horizontalRestriction: number[], //array with min and max value
	setHorizontalRestriction: (val: number[]) => void,
	verticalRestriction: number[], //array with min and max value
	setVerticalRestriction: (val: number[]) => void,
}

type Materials = {
	low_poly_material: MeshStandardMaterial
	base_01: MeshStandardMaterial
	base_02: MeshStandardMaterial
	basement: MeshStandardMaterial
	woodenfloor: MeshStandardMaterial
	puzzle_04: MeshStandardMaterial
}

// exports

export {useThreeContext};
export default ThreeContext;