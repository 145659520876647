import { ConsoleLabel, ConsoleRow } from '.';
import { useGlobalContext } from '../../../context/contexts/global/GlobalContext';


const HintPackageSelect = () => {

	const {setToggleHintTimer } = useGlobalContext(); 

	return(
		<ConsoleRow>
			<ConsoleLabel>Hints</ConsoleLabel>
			Hint package is automatically set when puzzle is set
			<br></br><button onClick={() => setToggleHintTimer && setToggleHintTimer(true)}>start hint timer</button>
			<button onClick={() => setToggleHintTimer && setToggleHintTimer(false)}>pause hint timer</button>
		</ConsoleRow>
	);
};

export default HintPackageSelect;