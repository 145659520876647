import { lazy } from 'react';
import { RoomCheck } from '../../RoomCheck';

const roomKey = 'keuken';

const KeukenShell = lazy(() => import('./KeukenShell'));
const KeukenInterior1 = lazy(() => import('./KeukenInterior1'));
const KeukenInterior2 = lazy(() => import('./KeukenInterior2'));
const Fornuis = lazy(() => import('./PuzzleElements/Fornuis'));

export const Keuken = () => {

	// render
	return (
		<>
			{/* Modie's Room */}
			<RoomCheck roomKey={roomKey} scene={1}>
				<KeukenInterior1/>
			</RoomCheck>
			{/* Sandra's Room */}
			<RoomCheck roomKey={roomKey} scene={2}>
				<KeukenInterior2/>
			</RoomCheck>
			{/* Shell */}
			<RoomCheck roomKey={roomKey}>
				<KeukenShell/>
				<Fornuis position={[-2.7,0,0.11]} scale={1.1} rotation={[0,Math.PI /2,0]}/>
			</RoomCheck>
		</>
	);
};