import { directus } from '../../../../utilities/directus';
import { language } from '../../text/TextContext';

const HPTranslationsRequest = async() => {

	const response = {};

	await directus.items('HintPackages_translations').readByQuery({filter:{languages_code: {_eq: language}}}).then((data) => {
		if(!data.data) return;
		
		data.data.map((value) => {
			if(!value || !value.id) return;
			response[value.id] = value;
		});
	});

	return {
		response : response,
		collection: 'HintPackages_translations'
	};
};

export default HPTranslationsRequest;