import styled from 'styled-components';
import { useGlobalContext } from '../../../context/contexts/global/GlobalContext';
import { Button } from '../../Buttons/Buttons';
import seeIcon from '../../../assets/icons/see_icon.png';
import { useDataContext } from '../../../context/contexts/data/DataContext';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Interval } from 'limiter';
import { slideIn } from './sidebar/Inventory';
import Theme from '../../../style/theme';

const totalCooldownTime = 3;

export const ShowInteractablesButton = () => {

	const {showInteractables, setTutorialStep, tutorialStep, setActiveHint} = useGlobalContext();
	const { getFileURL } = useDataContext();

	const [cooldownTime, setCooldownTime] = useState<number>(totalCooldownTime);

	const interval = useRef<any>(null);


	useEffect(() => {
		if(cooldownTime < totalCooldownTime && !interval.current){
			setCooldownTime(0);
			interval.current = setInterval(() => {
				setCooldownTime(ct => ct + 0.1);
			}, 100);
		}
		if(cooldownTime >= totalCooldownTime){
			clearInterval(interval.current);
			interval.current = null;
			setCooldownTime(totalCooldownTime);
		}
	}, [cooldownTime]);
	const InteractablesIcon = useMemo(() => {
		return getFileURL && getFileURL('92102f59-4e2f-4764-b46e-95b5993358a6');
	},[]);
	return (
		<StyledButton 
			iconURL={InteractablesIcon} 
			onClick={() => {
				if(tutorialStep === 3.1){
					setTutorialStep && setTutorialStep(4);
					setActiveHint && setActiveHint(0);
				}
				showInteractables && showInteractables();
				setCooldownTime(0);
			}} 
			timeData={cooldownTime !== totalCooldownTime ? {time: cooldownTime, maxTime: totalCooldownTime} : null}
			disabled={cooldownTime !== totalCooldownTime}
		/>
	);
};

// styled components

const StyledButton = styled(Button)`
	animation: ${slideIn} 1s forwards;
	flex-shrink:0;
	margin-bottom: 0!important;
`;