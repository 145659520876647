//list of cms keys

export const items = [
	'48203c4d-8a43-4949-8f1b-7b39d15340fa', //verbruiksmetersprite
	'eb85c5fd-daae-4643-b689-cf315cdc378b', //wiekensprite
	'50e0dbdc-5f6c-4138-84cb-2d40b7488cb8', //maquette buisje sprite
	'c9b69319-8835-4ec5-8313-498c1b4d5f85',//empty
	'881d9442-eb7c-48ef-b6eb-aa33afc614de',//gaskraan sprite
	'44216e16-165b-4573-95db-5190b9c4d2fd',//luik hendel sprite
	'f690c4e7-b1bb-48a0-b9f3-5f708ddefd7b',//gasleiding sprite	
	'a90ee05f-0751-4b59-971b-5ab4529a04c2',//normale kabel
	'14dfbcc5-c659-4674-8d13-4dbfcb185642',//t kabel
	'dfc37edf-f098-4ffc-b7e5-4149c3e30f94',//t kabel 02
	'900b70b5-1ede-422f-9929-b3647136252a',//kruis kabel
	'a90ee05f-0751-4b59-971b-5ab4529a04c2',//normale kabel
	'e3122003-923c-4f20-89c3-246b1abde092',//bocht
	'0d5fd8ef-5e31-4289-93bf-4b96b30b2255',//call action // 13
	'db25dbd2-156d-4da9-8309-f60db37b0af9',//cooking action
	'78c5499e-3807-4b93-933b-cb178e5be8c9',//dishwasher action
	'777b135a-ca61-45f7-99a0-b8315ec91f22',//jacuzzi action
	'7c57269d-3632-4f18-aba4-c744d669cfad',//laundry action
	'62c10437-0f9c-49b0-93e5-a7226fcb3f31',//shower action
	'46ff035e-05a1-4977-82b2-3347540e3eff',//tv action //19
	'c9b69319-8835-4ec5-8313-498c1b4d5f85',//zonnepanelen
	'c9b69319-8835-4ec5-8313-498c1b4d5f85',//zonnepanelen
	'c9b69319-8835-4ec5-8313-498c1b4d5f85',//zonnepanelen
	'5809d7bd-4f38-4196-9848-869a82207071',//key
	'6cd62a01-857e-4b47-a321-297b2d3845a5',//fornuis maquette model
	
];
// import verbruiksmeter_sprite from '../../../assets/images/items//verbruiksmeter_sprite.png';
// import luik_hendel_sprite from '../../../assets/images/items//luik_hendel_sprite.png';
// import pijpleiding_sprite from '../../../assets/images/items//pijpleiding_sprite.png';
// import gaskraan_sprite from '../../../assets/images/items//gaskraan_sprite.png';
// import wieken_sprite from '../../../assets/images/items//wieken_sprite.png';
// import zonnepanelen_sprite from '../../../assets/images/items//zonnepanelen_sprite.png';

// export const items = [
// 	verbruiksmeter_sprite,
// 	wieken_sprite,
// 	pijpleiding_sprite,
// 	zonnepanelen_sprite,
// 	gaskraan_sprite,
// 	luik_hendel_sprite

// ];