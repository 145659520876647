import { useMemo } from 'react';
import styled from 'styled-components';
import soundlist from '../../../assets/Sounds';
import { useAudioContext } from '../../../context/contexts/audio/AudioContext';
import { useDataContext } from '../../../context/contexts/data/DataContext';
import { useGlobalContext } from '../../../context/contexts/global/GlobalContext';
import { useTextContext } from '../../../context/contexts/text/TextContext';
import Theme from '../../../style/theme';
import { Button } from '../../Buttons/Buttons';

export const SelectionBlock = () => {

	const { activeScene, setActiveScene, setGameState} = useGlobalContext();
	const { getFileURL } = useDataContext();
	const {t} = useTextContext();
	const {playBackgroundSound} = useAudioContext();

	const arrowLeft = useMemo(() => {
		return getFileURL && getFileURL('66cd6a72-11d5-4806-b2be-93ae37de2727');
	},[]);

	const arrowRight = useMemo(() => {
		return getFileURL && getFileURL('a620bfd0-3d88-425d-a1fe-aa7729812da2');
	},[]);

	const portraitModie = useMemo(() => {
		return getFileURL && getFileURL('795590ae-75e2-41e7-adbd-d76435df2c59');
	},[]);

	const portraitSandra = useMemo(() => {
		return getFileURL && getFileURL('9019eb7e-98ce-41e3-9de0-1f9230b5988b');
	},[]);


	return (
		<Container>
			<Columns>
				<Column>
					<StyledButton iconURL={arrowLeft} onClick={() => setActiveScene && setActiveScene(activeScene === 1 ? 2 : 1)}/>
				</Column>
				<Column style={{flexGrow: 1}}>
					<div style={{overflowY: 'auto'}}>
						<Rows>
							<Row>
								<Nameplate>
									<h1>{t(`selection.nameplate.${activeScene}`)}</h1>
								</Nameplate>
							</Row>
							<Row style={{display: 'flex', gap: '10px'}}>
								<Portrait imageURL={ activeScene === 2 ? portraitModie : portraitSandra}/>
								<div style={{paddingInline: '20px'}}>
									<p style={{margin: 0}}>
										{t(`selection.descriptions.${activeScene === 1 ? 'modi' : 'sandra'}`)}
									</p>
									<div style={{display: 'flex', marginTop: '20px', gap: '20px'}}>
										<div style={{width: '50%'}}>
											<h2 style={{margin: 0}}>{t('selection.characteristics')}</h2>
											<div>○ {t(`selection.characteristics.${activeScene === 1 ? 'modi' : 'sandra'}.1`)}</div>
											<div>○ {t(`selection.characteristics.${activeScene === 1 ? 'modi' : 'sandra'}.2`)}</div>
											<div>○ {t(`selection.characteristics.${activeScene === 1 ? 'modi' : 'sandra'}.3`)}</div>
										</div>
										<div style={{width: '50%'}}>
											<h2 style={{margin: 0}}>{t('selection.hobbies')}</h2>
											<div>○ {t(`selection.hobbies.${activeScene === 1 ? 'modi' : 'sandra'}.1`)}</div>
											<div>○ {t(`selection.hobbies.${activeScene === 1 ? 'modi' : 'sandra'}.2`)}</div>
											<div>○ {t(`selection.hobbies.${activeScene === 1 ? 'modi' : 'sandra'}.3`)}</div>
										</div>
									</div>
								</div>

							</Row>
							<Row style={{flexGrow: 1}}>
								<Columns>
	

								</Columns>
							</Row>
							<Row style={{display: 'flex'}}>
								<Button style={{marginInline:'auto'}} onClick={() => {
									playBackgroundSound && playBackgroundSound(true, soundlist['backgroundMusic'], 0.1);
									setGameState && setGameState(3);
								}}>Start spel</Button>
							</Row>
						</Rows>
					</div>

				</Column>
				<Column>
					<StyledButton iconURL={arrowRight} onClick={() => setActiveScene && setActiveScene(activeScene === 1 ? 2 : 1)}/>
				</Column>
			</Columns>
		</Container>
	);
};

// styled components

const Container = styled.div`
	background: rgba(0,0,0,0.5);
	flex-grow: 1;
    padding: 20px;
`;

const Columns = styled.div`
    /* background: hotpink; */
    display: flex;
    flex-direction: row;
    height: 100%;
    gap: 10px;
`;

const Column = styled.div`
    display: flex;
    height: 100%;
`;

const Rows = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
	width: 100%;
	gap: 10px;
`;

const Row = styled.div`
    width: 100%;
	position: relative;
`;

const Nameplate = styled.div`
    background-color:  ${Theme.colors.primary};
    color: white;
    padding: 10px 30px;

	width: 250px;
	text-align: center;

    & > * {
        padding: 0;
        margin: 0;
    }
`;

const StyledButton = styled(Button)`
    height: 60px;
    width: 60px;
    padding:0;
    margin-block: auto;
`;

const Portrait = styled.div<{imageURL: string}>`
	background-image: url(${p => p.imageURL});
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	min-width: 250px;
	height: 250px;

	background-color: ${Theme.colors.neutral};
`;