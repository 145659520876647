import { createContext, ReactNode, useContext, useEffect, useState} from 'react';
import { Vector3 } from 'three';
import { CameraType } from '../../../components/Three/controls/Movement/CameraMovement';

const GlobalContext = createContext<Partial<GlobalContextProps>>({});

const GlobalContextProvider = (props: ContextProviderProps) => {

	//#region usestates
	const [gameState, setGameState] = useState<number>(1);

	const [displayConsole, setDisplayConsole] = useState<boolean>(false);
	const [showHitboxes, setShowHitboxes] = useState<boolean>(false);

	const [cameraType, setCameraType] = useState<CameraType>('movement');
	const [cameraAction, setCameraAction] = useState<CameraAction>();
	const [cursorState, setCursorState] = useState<CursorStates>('pointer');
	const [activeTutorialMessage, setActiveTutorialMessage] = useState<string>('');
	const [tutorialStep, setTutorialStep] = useState<number>(0);

	const [activeDialogueMessage, setActiveDialogueMessage] = useState<string>('');

	//hint system
	const [activeHint, setActiveHint ] = useState<number | undefined>();//not 0 because then timer will go off, see useEffect below
	const [toggleHintTimer, setToggleHintTimer] = useState<boolean>(false);

	// scene management
	const [activeScene, setActiveScene] = useState<number>(1);
	const [activeRoom, setActiveRoom] = useState<string>('hal');
	const [activatedRooms, setActivatedRooms] = useState<string[]>([]);
	const [queuedRoom, setQueuedRoom] = useState<string | undefined>();
	const [queuedRoomPosition, setQueuedRoomPosition] = useState<Vector3 | undefined>();
	const [queuedRoomDirection, setQueuedRoomDirection] = useState<Direction | undefined>();

	// puzzle management

	// const []

	//outlines

	const [interactablesVisible, setInteractablesVisible] = useState<boolean>(false);

	//#endregion

	//#region functions

	const onToggleDisplayConsole = () => {
		setDisplayConsole(e => !e);
	};
	
	const setTransportation = (room: string, position: Vector3, facing: Direction) => {

		setQueuedRoom(room);
		setQueuedRoomPosition(position);
		setQueuedRoomDirection(facing);
	};

	const showInteractables = () => {
		setInteractablesVisible(true);
		setTimeout(() => setInteractablesVisible(false), 3000);
	};	

	//#endregion
	
	//#region useEffects
	// const interval = useRef<any | null>(null);

	useEffect(() => {
		//start new timer for next hint after current one has been closed
		if(activeHint === 0){
			setToggleHintTimer(true);
		}
	},[activeHint]);

	useEffect(() => { 
		if(!queuedRoomPosition) return;
		else{
			setCameraAction({type: 'reset', parameters: {position: queuedRoomPosition, facing: queuedRoomDirection}});
			setQueuedRoomPosition(undefined);
		}
	},[activeRoom]);

	// Adds rooms to the activatedRooms array.
	useEffect(() => { 
		if(!activeRoom || !activeScene) return;

		if(!activatedRooms.includes('activeRoom')){
			setActivatedRooms(a => [...a, `${activeRoom}-${activeScene}`, `${activeRoom}`]);
		}
	},[activeRoom, activeScene]);

	useEffect(() => {
		switch(cursorState){
		case 'interact': 
			// document.body.style.cursor = 'zoom-in';
			break;
		case 'transport': 
			// document.body.style.cursor = 'url(./assets/icons/interactCursor.png)';
			break;
		case 'moving': 
			// document.body.style.cursor = 'none';
			break;
		default:
			document.body.style.cursor = 'grab';
			break;
		}
	},[cursorState]);

	//#endregion

	//#region passed functions & values
	
	const passedFunctions = {
		//console
		onToggleDisplayConsole,
		setDisplayConsole,

		//camera
		setCameraType,
		setCameraAction,

		//hints
		setActiveHint,
		setToggleHintTimer,
		
		//tutorial
		setActiveTutorialMessage,
		setTutorialStep,

		//dialogue
		setActiveDialogueMessage,

		//game state
		setGameState,

		// scene management

		setActiveScene,
		setActiveRoom,
		setQueuedRoom,
		setTransportation,

		// cursor state
		setCursorState,

		// showInteractables
		showInteractables,
		setShowHitboxes,
	};

	const passedValues = {
		//console		
		displayConsole,

		//camera
		cameraType,
		cameraAction,

		//hints
		activeHint,
		toggleHintTimer,

		//tutorial 
		activeTutorialMessage,
		tutorialStep,

		//dialogue
		activeDialogueMessage,

		//game state
		gameState,

		// scene management

		activeScene,
		activeRoom,
		activatedRooms,
		queuedRoom,
		queuedRoomPosition,
		queuedRoomDirection,

		// cursor state
		cursorState,

		// outline
		interactablesVisible,

		showHitboxes,


	};		

	//#endregion

	//#region render

	return (
		<GlobalContext.Provider value={{...passedValues, ...passedFunctions}}>
			{props.children}
		</GlobalContext.Provider>
	);

	//#endregion
};

// types

type GlobalContextProps = {
	//console
	setDisplayConsole: (val: boolean) => void,
	onToggleDisplayConsole: () => void,
	displayConsole: boolean,

	//camera
    cameraType: CameraType,
    setCameraType: (type: CameraType) => void,
    cameraAction: CameraAction | undefined,
    setCameraAction: (action: CameraAction | undefined) => void,

	//hints
	activeHint: number | undefined,
	setActiveHint: (val: number | undefined) => void,
	toggleHintTimer: boolean
	setToggleHintTimer: (val: boolean) => void,

	//tutorial
	activeTutorialMessage: string, 
	setActiveTutorialMessage: (val: string) => void, 
	tutorialStep: number,
	setTutorialStep: (val: number) => void

	//dialogue
	activeDialogueChat: number, 

	activeDialogueMessage: string, 
	setActiveDialogueMessage: (val: string) => void, 

	// scene management
	activeScene: number // dictates which scene/house is rendered
	setActiveScene: (val: number) => void

	activeRoom: string, // dictates which room is rendered
	activatedRooms: string[],
	setActiveRoom: (val: string) => void

	queuedRoom: string,
	setQueuedRoom: (val: string | undefined) => void

	queuedRoomPosition: Vector3 | undefined,
	setQueuedRoomPosition: (val: Vector3 | undefined) => void,

	setTransportation: (room: string, position: Vector3, facing: Direction) => void,
	
	//game state
	gameState: number,
	setGameState: (val: number) => void,

	// cursor state
	setCursorState: (val: CursorStates) => void,

	// outline
	interactablesVisible: boolean
	showInteractables: () => void
	showHitboxes: boolean
	setShowHitboxes: (val: boolean) => void
}

type ContextProviderProps = {
    children: ReactNode
}

type CameraAction = {
    type: string,
    parameters?: {
        position: Vector3
        target?: Vector3
		facing?: Direction
        type?: CameraType
    }
}

type CursorStates = 'interact' | 'drag' | 'pickup' | 'pointer' | 'transport' | 'moving'

export type Direction = 'x+' |  'x+z+' | 'z+' |  'z+x-' | 'x-' | 'x-z-' | 'z-' | 'z-x+'


// exports

const useGlobalContext = () => useContext(GlobalContext);

export {GlobalContextProvider, GlobalContext, useGlobalContext};
