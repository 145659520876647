import styled from 'styled-components';
import { keyframes } from 'styled-components';
import { useInventoryContext } from '../../../../context/contexts/inventory/InventoryContext';
import Theme from '../../../../style/theme';
import { InventoryItem } from './InventoryItem';

export const Inventory = () => {

	const {inventory} = useInventoryContext();

	return (
		<Container>
			<InventoryList>
				{ inventory && inventory.map((id) => 
					<InventoryItem key={`inventory-item-${id}`} id={id}/>
				)}
			</InventoryList>
		</Container>
	);
};

//styled components
export const slideIn = keyframes`
	0% {
	  transform: translateX(-1000px);
	}
	100% {
	  transform: translateX(0);
	}
`;

const Container = styled.div`
	animation: ${slideIn} 1s forwards;

	flex-grow: 1;
	background: rgba(0,0,0,0.5);
	border-radius: 0 10px 10px 0;
	overflow: hidden;
	padding: 5px;
`;

const InventoryList = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-y: auto;
	height: 100%;
	width: 100%;
	gap: 5px;
`;